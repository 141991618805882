import { Grid, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ButtonComponent from '../../../Component/ButtonComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import { setEndDateFilter, setStartDateFilter } from '../../../Filter/DateFilters';
import ApiHandler from '../../../Handler/ApiHandler';
import { Form, initFormHandler } from '../../../Handler/FormHandler';
import { listingStyles } from '../../../Style/useStyles';
import { MovementAction, MovementActionTranslation } from '../../../typings/movement';

type Option = {
  value: number;
  label: string;
};

export type MovementData = {
  id: string;
  byProductSku: string;
  productName: string;
  mark: string;
  packSize: string;
  flavor: string;
  amountText: string;
  purveyor: string;
  deposit: string;
  channel: string;
  comment: string;
  batch: string;
  movementAction: string;
  createdAt: string;
};

type Props = {
  loading: boolean;
  fetchData: (filters: any) => void;
};

const SearchForm: React.FC<Props> = ({ loading, fetchData }) => {
  const [optionsPurveyor, setOptionsPurveyor] = useState<Option[]>([]);
  const [optionsDeposit, setOptionsDeposit] = useState<Option[]>([]);
  const [optionsChannel, setOptionsChannel] = useState<Option[]>([]);
  const [optionsMark, setOptionsMark] = useState<Option[]>([]);
  const [optionsPackSize, setOptionsPackSize] = useState<Option[]>([]);
  const [optionsFlavor, setOptionsFlavor] = useState<Option[]>([]);
  const [formSearch, setFormSearch] = useState<Form>({
    byProductSku: {
      name: 'byProductSku',
      label: 'SKU',
      textHelper: 'Rechercher par codes.',
      type: 'text',
    },
    purveyor: {
      name: 'purveyor',
      label: 'Fournisseur',
      textHelper: 'Rechercher par fournisseurs.',
      type: 'integer',
    },
    deposit: {
      name: 'deposit',
      label: 'Dépot',
      textHelper: 'Rechercher par dépots.',
      type: 'integer',
    },
    channel: {
      name: 'channel',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'integer',
    },
    movementAction: {
      name: 'movementAction',
      label: 'Action',
      textHelper: 'Rechercher par actions.',
      type: 'integer',
    },
    batch: { name: 'batch', label: 'Lot', textHelper: 'Rechercher par lots.', type: 'text', defaultValue: '' },
    mark: {
      name: 'mark',
      label: 'Marque',
      textHelper: "Rechercher par marque d'article.",
      type: 'integer',
    },
    byProduct: {
      name: 'byProduct',
      label: 'Article',
      textHelper: "Rechercher par nom d'article.",
      type: 'text',
    },
    packSize: {
      name: 'packSize',
      label: 'Conditionnement',
      textHelper: 'Rechercher par conditionnement.',
      type: 'integer',
    },
    flavor: {
      name: 'flavor',
      label: 'Parfum',
      textHelper: 'Rechercher par parfum.',
      type: 'integer',
    },
    startDate: {
      name: 'startDate',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      options: { validation: ['date'] },
    },
    endDate: {
      name: 'endDate',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      options: { validation: ['date'] },
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index: string, value: any) => {
    handlerFormSearch.setValue(index, value);
  };

  const fetchOptions = async () => {
    const routes = [
      { route: 'api_select_purveyor', setter: setOptionsPurveyor },
      { route: 'api_select_deposit', setter: setOptionsDeposit },
      { route: 'api_select_channel', setter: setOptionsChannel },
      { route: 'api_select_mark', setter: setOptionsMark },
      { route: 'api_select_pack_size', setter: setOptionsPackSize },
      { route: 'api_select_flavor', setter: setOptionsFlavor },
    ];

    for (const { route, setter } of routes) {
      ApiHandler.get({ route }, (response) => {
        setter([{ value: 0, label: 'Tous' }, ...response.data]);
      });
    }
  };

  useEffect(() => {
    handlerFormSearch.start();
    fetchOptions();
  }, []);

  const onSearch = () => {
    const datas: any = handlerFormSearch.getData();
    fetchData({
      byProductSku: formSearch.byProductSku.value ? formSearch.byProductSku.value : '',
      purveyor: datas.purveyor === 0 || !datas.purveyor ? '' : datas.purveyor,
      deposit: datas.deposit === 0 || !datas.deposit ? '' : datas.deposit,
      channel: datas.channel === 0 || !datas.channel ? '' : datas.channel,
      movementAction: datas.movementAction === 0 || !datas.movementAction ? '' : datas.movementAction,
      batch: formSearch.batch.value ? formSearch.batch.value : '',
      byProduct: formSearch.byProduct.value ? formSearch.byProduct.value : '',
      mark: datas.mark === 0 || !datas.mark ? '' : datas.mark,
      flavor: datas.flavor === 0 || !datas.flavor ? '' : datas.flavor,
      packSize: datas.packSize === 0 || !datas.packSize ? '' : datas.packSize,
      startDate: formSearch.startDate.value ? setStartDateFilter(formSearch.startDate.value) : '',
      endDate: formSearch.endDate.value ? setEndDateFilter(formSearch.endDate.value) : '',
    });
  };

  return (
    <Slide direction="left" in timeout={750}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ShadowBoxComponent>
          <TitleComponent title="Filtres" />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={4}>
              <TextFieldComponent
                id="byProductSku"
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('byProductSku', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <SelectComponent
                id="purveyor"
                options={optionsPurveyor}
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('purveyor', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <SelectComponent
                id="deposit"
                options={optionsDeposit}
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('deposit', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <SelectComponent
                id="channel"
                options={optionsChannel}
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('channel', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
              <SelectComponent
                id="movementAction"
                options={(Object.keys(MovementActionTranslation) as unknown as MovementAction[]).map(
                  (movementAction) => ({
                    value: movementAction,
                    label: MovementActionTranslation[movementAction],
                  }),
                )}
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('movementAction', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextFieldComponent
                id="byProduct"
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('byProduct', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
              <SelectComponent
                id="mark"
                options={optionsMark}
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('mark', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
              <SelectComponent
                id="packSize"
                options={optionsPackSize}
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('packSize', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
              <SelectComponent
                id="flavor"
                options={optionsFlavor}
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('flavor', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
              <TextFieldComponent
                id="batch"
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('batch', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <DatePickerComponent
                id="startDate"
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('startDate', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <DatePickerComponent
                id="endDate"
                handler={handlerFormSearch}
                onChange={(val) => inputSearchChange('endDate', val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <ButtonComponent
                className={listingStyles().buttonActionFirst}
                label="Rechercher"
                loading={loading}
                onClick={() => onSearch()}
              />
            </Grid>
          </Grid>
        </ShadowBoxComponent>
      </Grid>
    </Slide>
  );
};

export default SearchForm;
