import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import ButtonComponent from '../../../../Component/ButtonComponent';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { Form, initFormHandler } from '../../../../Handler/FormHandler';
import { Filters } from '../../../../View/Statistic/Order/StatisticOrder';
import { Channels, ChannelSelect } from '../../../../typings/channel';
import { OrderType, OrderTypeTranslation } from '../../../../typings/orderDocument';
import { PaymentMethod, PaymentMethodTranslation } from '../../../../typings/orderPayment';
import { ShopSelect } from '../../../../typings/shop';

type Props = {
  handleSearch: (data: Filters) => void;
  loading: boolean;
  filters: Filters;
};

const Search: React.FC<Props> = ({ handleSearch, loading, filters }) => {
  const classes = useStyles();
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [optionsChannel, setOptionsChannel] = useState<ChannelSelect[]>([]);
  const [optionsShop, setOptionsShop] = useState<ShopSelect[]>([]);
  const [withShops, setWithShops] = useState<boolean>(false);

  const [formSearch, setFormSearch] = useState<Form>({
    startDate: {
      name: 'startDate',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      options: { validation: ['date'] },
      defaultValue: filters.startDate,
    },
    endDate: {
      name: 'endDate',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      options: { validation: ['date'] },
      defaultValue: filters.endDate,
    },
    types: {
      name: 'types',
      label: 'Type',
      textHelper: 'Rechercher par type.',
      type: 'array',
    },
    channels: {
      name: 'channels',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'array',
    },
    shops: {
      name: 'shops',
      label: 'Boutique',
      textHelper: 'Rechercher par boutiques.',
      type: 'array',
    },
    paymentMethods: {
      name: 'paymentMethods',
      label: 'Methode de paiement',
      textHelper: 'Rechercher par methode de paiement.',
      type: 'array',
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get<ChannelSelect[]>({ route: 'api_select_channel' }, (response) =>
      setOptionsChannel(response.data?.filter((channel) => channel.value !== Channels.REAPPROBOUTIQUES) ?? []),
    );

    ApiHandler.get<ShopSelect[]>({ route: 'api_select_shop' }, (response) => setOptionsShop(response.data ?? []));
  }, []);

  const getData = (): Filters => {
    const data = handlerFormSearch.getData();

    const paymentMethods: PaymentMethod[] = [];
    data.paymentMethods.forEach((paymentMethod: string) => {
      Object.entries(PaymentMethodTranslation).forEach(([key, value]) => {
        if (value === paymentMethod) {
          paymentMethods.push(key as PaymentMethod);
        }
      });
    });

    return {
      startDate: data.startDate,
      endDate: data.endDate,
      channels: data.channels,
      types: data.types,
      shops: data.shops,
      paymentMethods,
    };
  };

  const download = () => {
    setLoadingExport(true);

    const aElement = document.createElement('a');
    aElement.href = ApiHandler.route({ route: 'api_statistic_order_document_download', data: getData() }) as string;
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);

    setTimeout(() => setLoadingExport(false), 10000);
  };

  return (
    <ShadowBoxComponent>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={3}>
          <DatePickerComponent id="startDate" handler={handlerFormSearch} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DatePickerComponent id="endDate" handler={handlerFormSearch} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <MultipleSelectComponent
            id="channels"
            options={optionsChannel}
            handler={handlerFormSearch}
            onChange={(value) => {
              const withShops = (value as unknown as Channels[]).includes(Channels.BOUTIQUES);
              setWithShops(withShops);
              if (!withShops) {
                handlerFormSearch.setValue('shops', []);
              }
            }}
          />
        </Grid>
        {withShops && (
          <Grid item xs={12} sm={6} lg={3}>
            <MultipleSelectComponent id="shops" options={optionsShop} handler={handlerFormSearch} />
          </Grid>
        )}
        <Grid item xs={12} sm={6} lg={3}>
          <MultipleSelectComponent
            id="types"
            options={(Object.keys(OrderTypeTranslation) as OrderType[])
              .filter((orderType) => orderType !== OrderType.DELIVERY_NOTE_ORDER_CLIENT)
              .map((orderType) => ({
                value: orderType,
                label: OrderTypeTranslation[orderType],
              }))}
            handler={handlerFormSearch}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <MultipleSelectComponent
            id="paymentMethods"
            options={Array.from(new Set(Object.values(PaymentMethodTranslation))).map((paymentMethod) => ({
              value: paymentMethod,
              label: paymentMethod,
            }))}
            handler={handlerFormSearch}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ButtonComponent
            className={classes.button}
            label="Rechercher"
            onClick={() => handleSearch(getData())}
            disabled={loading}
            loading={loading}
          />
          <ButtonComponent
            className={classes.button}
            label="Telecharger"
            onClick={() => download()}
            disabled={loadingExport}
            loading={loadingExport}
          />
        </Grid>
      </Grid>
    </ShadowBoxComponent>
  );
};

const useStyles = makeStyles({
  button: {
    margin: '6px !important',
  },
});

export default Search;
