import { Grid } from '@mui/material';
import React, { useState } from 'react';

import { ByProduct } from '#app/typings/order';

import ButtonComponent from '../../../../../../Component/ButtonComponent';
import CheckboxComponent from '../../../../../../Component/CheckboxComponent';
import TextFieldComponent from '../../../../../../Component/TextFieldComponent';
import { Form, FormField, initFormHandler } from '../../../../../../Handler/FormHandler';
import { Payload } from '../../../../../../View/Sale/OrderClient/SaleOrderClient/components/CreditModal/index';

type Props = {
  onClose: () => void;
  products: ByProduct[];
  loading: boolean;
  onSubmit: (payload: Payload) => void;
};

const DisputeOrder: React.FC<Props> = ({ onClose, products, loading, onSubmit }) => {
  const [form, setForm] = useState<Form>({
    reason: {
      name: 'reason',
      label: 'Raison',
      textHelper: 'Saisissez une raison.',
      type: 'text',
      options: { validation: ['required'] },
    },
    refundCarrier: {
      name: 'refundCarrier',
      label: 'Rembourser les frais de livraison',
      type: 'boolean',
      defaultValue: true,
    },
    refundPayment: {
      name: 'refundPayment',
      label: 'Rembourser les frais de payment',
      type: 'boolean',
      defaultValue: true,
    },
    ...products.reduce((acc: Record<string, FormField>, product) => {
      acc[product.id] = {
        name: product.id.toString(),
        label: 'Quantité',
        textHelper: `Saisissez la quantité de produit en litige (maximum : ${product.amountShipped - product.amount - product.amountLitige})`,
        type: 'integer',
        options: {
          validation: ['required'],
          max: product.amountShipped - product.amount - product.amountLitige,
        },
      };
      return acc;
    }, {}),
  });

  const handlerForm = initFormHandler(form, setForm);

  React.useEffect(() => {
    handlerForm.start();
  }, []);

  const handleSubmit = (): void => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);

      const data = handlerForm.getData();

      onSubmit({
        reason: data.reason,
        products: Object.entries(data)
          .filter(([key]) => !isNaN(parseInt(key)))
          .map(([key, value]) => ({
            quantity: value as number,
            byProduct: parseInt(key),
          })),
        refundCarrier: data.refundCarrier,
        refundPayment: data.refundPayment,
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <TextFieldComponent id="reason" handler={handlerForm} multiline />
      </Grid>

      {products.map((product) => (
        <React.Fragment key={product.id}>
          <Grid item xs={6}>
            <p>{product.name}</p>
          </Grid>
          <Grid item xs={6}>
            <TextFieldComponent id={product.id.toString()} handler={handlerForm} />
          </Grid>
        </React.Fragment>
      ))}

      <Grid item xs={12}>
        <CheckboxComponent id="refundCarrier" handler={handlerForm} />
        <CheckboxComponent id="refundPayment" handler={handlerForm} />
      </Grid>

      <Grid item container spacing={2} justifyContent="end">
        <Grid item>
          <ButtonComponent color="#5E6E82" label="Annuler" onClick={onClose} loading={loading} />
        </Grid>
        <Grid item>
          <ButtonComponent label="Enregistrer" onClick={handleSubmit} loading={loading} />
        </Grid>
      </Grid>
    </>
  );
};

export default DisputeOrder;
