export enum DisputeType {
  OUT_OF_STOCK = 'out_of_stock',
  RETURN_TO_SENDER = 'return_to_sender',
  RETRACTION = 'retraction',
  PRODUCT_RETRACTION = 'product_retraction',
  PACKAGE_DAMAGED = 'package_damaged',
  PRODUCT_DAMAGED = 'product_damaged',
  NON_COMPLIANT_PRODUCT = 'non_compliant_product',
  PACKAGE_LOST = 'package_lost',
  MISSING_PRODUCT = 'missing_product',
  WRONG_ITEM = 'wrong_item',
  ORDER_CANCELLATION = 'order_cancellation',
  SHIPPING_FEES = 'shipping_fees',
  PROMO_CODE = 'promo_code',
}

export const DisputeTypeTranslations: Record<DisputeType, string> = {
  [DisputeType.OUT_OF_STOCK]: 'Rupture de stock',
  [DisputeType.RETURN_TO_SENDER]: 'Retour expéditeur',
  [DisputeType.RETRACTION]: 'Rétractation',
  [DisputeType.PRODUCT_RETRACTION]: 'Rétractation Produit',
  [DisputeType.PACKAGE_DAMAGED]: 'Colis cassé',
  [DisputeType.PRODUCT_DAMAGED]: 'Produit cassé',
  [DisputeType.NON_COMPLIANT_PRODUCT]: 'Produit non conforme',
  [DisputeType.PACKAGE_LOST]: 'Colis perdu',
  [DisputeType.MISSING_PRODUCT]: 'Produit manquant',
  [DisputeType.WRONG_ITEM]: 'Erreur article',
  [DisputeType.ORDER_CANCELLATION]: 'Annulation commande',
  [DisputeType.SHIPPING_FEES]: 'Frais de port',
  [DisputeType.PROMO_CODE]: 'Code promo',
};

export enum DisputeState {
  PENDING_PROCESSING = 'pending_processing',
  PROCESSING = 'processing',
  CLOSED = 'closed',
  ARCHIVED = 'archived',
}

export const DisputeStateTranslations: Record<DisputeState, string> = {
  [DisputeState.PENDING_PROCESSING]: 'En attente traitement',
  [DisputeState.PROCESSING]: 'Traitement en cours',
  [DisputeState.CLOSED]: 'Clôturé',
  [DisputeState.ARCHIVED]: 'Archivé',
};
