import { Select, InputLabel, FormControl, FormHelperText, MenuItem, Checkbox } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

type Props = {
  id: string;
  handler: {
    form: Record<string, any>;
    setValue: (id: string, value: any) => void;
    getErrorByField: (input: any) => string;
    setError: (id: string, error: string) => void;
  };
  options: {
    value: number | string | null | undefined;
    label: string;
  }[];
  onChange?: (value: (string | undefined)[]) => void;
  withSelectAll?: boolean;
};

const MultipleSelectComponent: React.FC<Props> = ({ id, handler, options, onChange, withSelectAll }) => {
  const input = handler.form[id];
  const label = input.label;

  const classes = useStyles();
  const [error, setError] = useState<string | null>(input.error);
  const [isFocus, setFocus] = useState(false);

  // Value
  const [value, setValue] = useState<(number | string | undefined)[]>([]);
  useEffect(() => {
    if (input.value !== undefined && input.value !== value) {
      setValue(input.value);
    }
  }, [input.value]);

  useEffect(() => {
    if (input.error !== error) {
      setError(input.error);
    }
  }, [input.error]);

  // Event Handlers
  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const inputValue = (event.target.value as (string | undefined)[]) ?? [];
    let newValue = inputValue;
    if (value.includes(undefined)) {
      newValue = inputValue.filter((element) => undefined !== element);
    } else if (inputValue.includes(undefined)) {
      newValue = [];
    }

    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleBlur = (): void => {
    setFocus(false);
    handler.setValue(id, value);
    const error = handler.getErrorByField(input);
    setError(error);
    handler.setError(id, error);
  };

  return (
    <div className={classes.content}>
      <FormControl
        variant="outlined"
        className={`
          ${classes.multipleSelect}
          ${
            isFocus
              ? classes.multipleSelectFocus
              : error
                ? classes.multipleSelectError
                : value && value.length > 0 && !input.disabled
                  ? classes.multipleSelectValid
                  : ''
          }
        `}
      >
        <InputLabel htmlFor="outlined">
          {label + (input.options?.validation?.includes('required') ? ' *' : '')}
        </InputLabel>
        <Select
          labelId={input.name}
          multiple
          value={value as string[]}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={() => setFocus(true)}
          autoFocus={false}
          inputProps={{ name: label + (input.options?.validation?.includes('required') ? ' *' : '') }}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            className: classes.menu,
          }}
          label={label + (input.options?.validation?.includes('required') ? ' *' : '')}
          disabled={input.disabled}
        >
          {withSelectAll && <MenuItem>Tous</MenuItem>}
          {options.map((data) => (
            <MenuItem key={data.value} value={data.value ?? undefined}>
              <Checkbox style={{ padding: 0 }} checked={value.includes(data.value ?? undefined)} />
              {data.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error && !isFocus ? error : input.textHelper}</FormHelperText>
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    padding: 5,
  },
  multipleSelect: {
    width: '100%',
    marginTop: 0,
    '& .MuiSelect-select': {
      padding: '7px 14px',
      fontSize: 13,
    },
    '& .MuiSelect-select:focus': {
      background: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(13px, 7px) scale(1)',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(13px, -7px) scale(0.75) !important',
    },
    '& .MuiFormHelperText-root': {
      margin: '2px 14px',
      fontSize: 9,
      height: 9,
      lineHeight: '9px',
    },
    '& fieldset': {
      borderWidth: '1px !important',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  multipleSelectFocus: {
    '& fieldset': {
      borderColor: '#3F51BF !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#3F51BF !important',
    },
    '& label': {
      color: '#3F51BF !important',
    },
  },
  multipleSelectValid: {
    '& fieldset': {
      borderColor: '#006500 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#006500 !important',
    },
    '& label': {
      color: '#006500 !important',
    },
  },
  multipleSelectError: {
    '& fieldset': {
      borderColor: '#982525 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#982525 !important',
    },
    '& label': {
      color: '#982525 !important',
    },
  },
  menu: {
    '& li': {
      fontSize: 12,
      padding: '4px 10px',
      lineHeight: '16px',
      minHeight: '16px',
    },
  },
});

export default MultipleSelectComponent;
