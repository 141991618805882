import { CloudDownload } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ApiHandler from '../../../../../Handler/ApiHandler';
import { styleDataGridDefault } from '../../../../../Style/styleDataGridDefault';
import { formatNumberToEurCurrency } from '../../../../../Util/NumberTool';
import { OrderDocument, OrderType, OrderTypeTranslation } from '../../../../../typings/orderDocument';

const DocumentList: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [documents, setDocuments] = useState<OrderDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    ApiHandler.get<OrderDocument[]>(
      {
        route: 'api_order_document_list',
        params: { uuid: uuid as string },
      },
      (response) => {
        if (response.data) {
          setDocuments(response.data);
        }
        setLoading(false);
      },
    );
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'orderType',
      headerName: 'Type',
      minWidth: 150,
      valueGetter: (value: OrderType) => OrderTypeTranslation[value],
    },
    {
      field: 'reference',
      headerName: 'Reference',
      flex: 1,
    },
    {
      field: 'pricePaidTTC',
      headerName: 'Montant',
      minWidth: 120,
      valueGetter: (value: number, row: OrderDocument) =>
        row.orderType !== OrderType.DELIVERY_NOTE_ORDER_CLIENT ? formatNumberToEurCurrency(value) : '-',
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'actions',
      getActions: ({ row }) => [
        <IconButton
          onClick={() => {
            fetch(ApiHandler.route({ route: 'api_order_document_download', params: { id: row.id } }) as string, {
              method: 'GET',
              mode: 'no-cors',
              referrerPolicy: 'no-referrer',
            })
              .then((response) => response.blob())
              .then((response) => {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', row.reference + '.pdf');
                const href = URL.createObjectURL(response);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
              });
          }}
        >
          <CloudDownload />
        </IconButton>,
      ],
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        sx={styleDataGridDefault}
        loading={loading}
        ignoreDiacritics
        columns={columns}
        rows={documents}
        disableColumnMenu
        rowHeight={30}
        disableRowSelectionOnClick
        hideFooter
      />
    </div>
  );
};

export default DocumentList;
