import { makeStyles } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import DataGrid from '../../../../Component/DataGrid';
import ApiHandler, { ApiResponse } from '../../../../Handler/ApiHandler';
import { transformObjectToDataGridRowsArray } from '../../../../Util/DataGridDataTransformer';
import { momentDateFormatter } from '../../../../Util/MomentDateFormatter';
import { formatNumberToEurCurrency, formatNumberWithThousandsSeparator } from '../../../../Util/NumberTool';
import { Filters } from '../../../../View/InventoryManagement/Stock/MovementStock';
import { MovementAction, MovementActionTranslation, MovementStockList } from '../../../../typings/movement';
import { Pagination } from '../../../../typings/utils';

type Props = {
  filters: Filters;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

type Response = ApiResponse<MovementStockList[]> & {
  totalItems: number;
};

const List: React.FC<Props> = ({ filters, loading, setLoading }) => {
  const classes = useStyles();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>({ page: 0, limit: 25 });
  const [movements, setMovements] = useState<MovementStockList[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Date',
      valueGetter: (value: string) => momentDateFormatter.dateTimeToFormatFr(value),
    },
    {
      field: 'comment',
      headerName: 'Commentaire',
      minWidth: 300,
    },
    {
      field: 'movementAction',
      headerName: 'Action',
      valueGetter: (value: MovementAction) => MovementActionTranslation[value],
      minWidth: 200,
    },
    {
      field: 'amount',
      headerName: 'Quantité',
      valueFormatter: (value) => formatNumberWithThousandsSeparator(value),
    },
    {
      field: 'price',
      headerName: 'DPA',
      valueFormatter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'sellPrice',
      headerName: 'Prix de vente',
      valueFormatter: (value) => formatNumberToEurCurrency(value),
    },
    {
      field: 'balance',
      headerName: 'Solde',
      valueFormatter: (value) => formatNumberWithThousandsSeparator(value),
    },
  ];

  const handleSearch = () => {
    setLoading(true);
    ApiHandler.get<MovementStockList[]>(
      {
        route: 'api_inventory_management_movement_stock',
        data: { ...filters, ...pagination },
      },
      (response) => {
        setMovements(response.data ?? []);
        setTotalItems((response as Response).totalItems ?? 0);
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    if (filters.byProduct) {
      handleSearch();
    }
  }, [pagination, filters]);

  return (
    <DataGrid
      className={classes.container}
      rows={transformObjectToDataGridRowsArray<MovementStockList>(movements)}
      columns={columns}
      loading={loading}
      rowCount={totalItems}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};

const useStyles = makeStyles({
  container: {
    marginTop: 15,
  },
});

export default List;
