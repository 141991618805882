import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Order } from '#app/typings/order';

import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import { dispatch } from '../../../../App';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import TabsComponent from '../../../../Component/TabsComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import DisputeList from '../../../../View/Sale/OrderClient/SaleOrderClient/components/DisputeList';
import DocumentList from '../../../../View/Sale/OrderClient/SaleOrderClient/components/DocumentList';
import History from '../../../../View/Sale/OrderClient/SaleOrderClient/components/History';
import ProductList from '../../../../View/Sale/OrderClient/SaleOrderClient/components/ProductList';
import Information from '../../../../View/Shop/Sale/ShopSaleOrderClient/components/Information';

const ShopSaleOrderClient: React.FC = () => {
  const params = useParams<{ uuid: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoading(true);

    ApiHandler.get<Order>(
      {
        route: 'api_sale_order_client',
        params: { uuid: params.uuid as string },
      },
      (response) => {
        if (response.status === 200) {
          setOrder(response.data);
          setTimeout(() => {
            dispatch(TableReload('api_sale_order_client_litige'));
          }, 100);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }

        setReload(false);
        setLoading(false);
      },
    );
  }, [reload, params.uuid]);

  return (
    <ContentViewComponent
      loading={loading}
      breadcrumbs={{
        title: 'Visualisation de la commande',
        context: 'Vente',
      }}
    >
      {order && (
        <>
          <Grid container spacing={1}>
            <Information order={order} reload={() => setReload(true)} />
            <History history={order.history} />
          </Grid>

          <br />

          <TabsComponent
            tabDefault="sale_order_client"
            tabs={[
              { key: 'sale_order_client', label: 'Articles' },
              { key: 'sale_order_client_litige', label: 'Litiges' },
              { key: 'sale_order_client_document', label: 'Documents' },
            ]}
          >
            <ProductList key="sale_order_client" products={order?.byProducts} loading={loading} />
            <DisputeList
              key="sale_order_client"
              disputes={order?.orderClientLitiges}
              loading={loading}
              orderState={order.stateId}
              products={order?.byProducts}
              canOpenDispute={false}
            />
            <DocumentList key="sale_order_client_document" />
          </TabsComponent>
        </>
      )}
    </ContentViewComponent>
  );
};

export default ShopSaleOrderClient;
