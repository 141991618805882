import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { dispatch } from '../../../../App';
import AutoCompleteComponent from '../../../../Component/AutoCompleteComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { Form, initFormHandler } from '../../../../Handler/FormHandler';
import { Filters } from '../../../../View/InventoryManagement/Stock/MovementStock';
import { Option } from '../../../../typings/utils';

type Props = {
  handleSearch: (data: Filters) => void;
  loading: boolean;
};

const Search: React.FC<Props> = ({ handleSearch, loading }) => {
  const classes = useStyles();
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [optionsDeposit, setOptionsDeposit] = useState<Option[]>([]);

  const [formSearch, setFormSearch] = useState<Form>({
    createdAt: {
      name: 'createdAt',
      label: 'Stock au',
      textHelper: 'Rechercher par date.',
      type: 'date',
    },
    byProduct: {
      name: 'byProduct',
      label: 'Article',
      textHelper: "Rechercher par nom d'article.",
      type: 'integer',
      options: { validation: ['required'] },
    },
    deposit: {
      name: 'deposit',
      label: 'Dépot',
      textHelper: 'Rechercher par dépots.',
      type: 'integer',
      options: { validation: ['required'] },
    },
  });

  const handlerForm = initFormHandler(formSearch, setFormSearch);

  const loadByProduct = (valueText: string, callback: (options: Option[]) => void): void => {
    ApiHandler.get<Option[]>(
      {
        route: 'api_auto_complete_by_product',
        data: {
          text: valueText,
        },
      },
      (response) => {
        if (response.status === 200) {
          callback(response.data ?? []);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
      },
    );
  };

  useEffect(() => {
    handlerForm.start();

    ApiHandler.get<Option[]>({ route: 'api_select_deposit' }, (response) => setOptionsDeposit(response.data ?? []));
  }, []);

  const getData = (): Filters => {
    const data = handlerForm.getData();

    return {
      createdAt: data.createdAt,
      byProduct: data.byProduct,
      deposit: data.deposit,
    };
  };

  const download = () => {
    setLoadingExport(true);

    const aElement = document.createElement('a');
    aElement.href = ApiHandler.route({
      route: 'api_inventory_management_movement_stock_download',
      data: getData(),
    }) as string;
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);

    setTimeout(() => setLoadingExport(false), 10000);
  };

  return (
    <ShadowBoxComponent>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <DatePickerComponent id="createdAt" handler={handlerForm} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <SelectComponent id="deposit" options={optionsDeposit} handler={handlerForm} />
        </Grid>
        <Grid size={{ xs: 12, sm: 9 }}>
          <AutoCompleteComponent id="byProduct" handler={handlerForm} loadOptions={loadByProduct} />
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }}>
          <ButtonComponent
            className={classes.button}
            label="Rechercher"
            onClick={() => handlerForm.checkError() < 1 && handleSearch(getData())}
            disabled={loading}
            loading={loading}
          />
          <ButtonComponent
            className={classes.button}
            label="Telecharger"
            onClick={() => download()}
            disabled={loadingExport}
            loading={loadingExport}
          />
        </Grid>
      </Grid>
    </ShadowBoxComponent>
  );
};

const useStyles = makeStyles({
  button: {
    margin: '6px !important',
  },
});

export default Search;
