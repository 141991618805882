import { Grid } from '@mui/material';
import React, { useState } from 'react';

import { OrderDocument } from '#app/typings/orderDocument';

import ButtonComponent from '../../../../../../Component/ButtonComponent';
import CheckboxComponent from '../../../../../../Component/CheckboxComponent';
import SelectComponent from '../../../../../../Component/SelectComponent';
import TextFieldComponent from '../../../../../../Component/TextFieldComponent';
import { Form, initFormHandler } from '../../../../../../Handler/FormHandler';
import { Payload } from '../../../../../../View/Sale/OrderClient/SaleOrderClient/components/CreditModal/index';

type Props = {
  documents: OrderDocument[];
  onClose: () => void;
  loading: boolean;
  onSubmit: (payload: Payload) => void;
};

const DisputeModal: React.FC<Props> = ({ documents, onClose, loading, onSubmit }) => {
  const [form, setForm] = useState<Form>({
    reason: {
      name: 'reason',
      label: 'Raison',
      textHelper: 'Saisissez une raison.',
      type: 'text',
      options: { validation: ['required'] },
    },
    refundCarrier: {
      name: 'refundCarrier',
      label: 'Rembourser les frais de livraison',
      type: 'boolean',
      defaultValue: true,
    },
    refundPayment: {
      name: 'refundPayment',
      label: 'Rembourser les frais de payment',
      type: 'boolean',
      defaultValue: true,
    },
    ...(documents.length > 1
      ? {
          orderDocument: {
            name: 'orderDocument',
            label: 'Facture',
            textHelper: 'Selectionnez la facture en litige.',
            type: 'integer',
            options: { validation: ['required'] },
          },
        }
      : {}),
  });

  const handlerForm = initFormHandler(form, setForm);

  React.useEffect(() => {
    handlerForm.start();
  }, []);

  const handleSubmit = (): void => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);
      const data = handlerForm.getData();

      onSubmit({
        reason: data.reason,
        orderDocument: (data.orderDocument ?? documents[0].id) as number,
        refundCarrier: data.refundCarrier,
        refundPayment: data.refundPayment,
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <TextFieldComponent id="reason" handler={handlerForm} multiline />
      </Grid>
      {documents.length > 1 && (
        <Grid item xs={12}>
          <SelectComponent
            id="orderDocument"
            options={documents.map((document) => ({
              value: document.id,
              label: document.reference,
            }))}
            handler={handlerForm}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <CheckboxComponent id="refundCarrier" handler={handlerForm} />
        <CheckboxComponent id="refundPayment" handler={handlerForm} />
      </Grid>

      <Grid item container spacing={2} justifyContent="end">
        <Grid item>
          <ButtonComponent color="#5E6E82" label="Annuler" onClick={onClose} loading={loading} />
        </Grid>
        <Grid item>
          <ButtonComponent label="Enregistrer" onClick={handleSubmit} loading={loading} />
        </Grid>
      </Grid>
    </>
  );
};

export default DisputeModal;
