import { makeStyles } from '@mui/styles';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { ComponentProps } from 'react';

import { CustomPagination } from '../../Component/DataGrid/CustomPagination';
import { styleDataGridDefault } from '../../Style/styleDataGridDefault';
import { Pagination, Sorting } from '../../typings/utils';

type Props = Omit<ComponentProps<typeof DataGrid>, 'pagination'> & {
  pagination?: Pagination;
  sorting?: Sorting;
  setPagination?: (pagination: Pagination) => void;
  setSorting?: (sorting: Sorting) => void;
};

const DateGrid: React.FC<Props> = ({ pagination, sorting, setPagination, setSorting, sx, ...props }) => {
  const classes = useStyles();
  const apiRef = useGridApiRef();

  return (
    <div className={classes.container}>
      <DataGrid
        apiRef={apiRef}
        sx={{ ...styleDataGridDefault, ...sx }}
        rowHeight={25}
        disableRowSelectionOnClick
        disableColumnMenu
        slots={{ pagination: CustomPagination }}
        sortingMode={setSorting ? 'server' : 'client'}
        onSortModelChange={(sort) => {
          if (setSorting) {
            setSorting({ sortName: sort[0]?.field, sortDirection: sort[0]?.sort as 'asc' | 'desc' });
          }
          apiRef.current.setPage(0);
          if (setPagination && pagination) {
            setPagination({ page: 0, limit: pagination.limit });
          }
        }}
        paginationMode={setPagination ? 'server' : 'client'}
        onPaginationModelChange={
          setPagination ? ({ pageSize, page }) => setPagination({ page, limit: pageSize }) : undefined
        }
        initialState={{
          pagination: { paginationModel: pagination ? { pageSize: pagination.limit } : undefined },
          sorting: sorting ? { sortModel: [{ field: sorting.sortName, sort: sorting.sortDirection }] } : undefined,
        }}
        {...props}
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default DateGrid;
