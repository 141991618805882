import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ByProduct } from '#app/typings/order';

import { SnackbarOpen } from '../../../../../../Action/SnackbarAction';
import { dispatch } from '../../../../../../App';
import ButtonComponent from '../../../../../../Component/ButtonComponent';
import SelectInput from '../../../../../../Component/SelectInput';
import ApiHandler from '../../../../../../Handler/ApiHandler';
import DisputeInvoice from '../../../../../../View/Sale/OrderClient/SaleOrderClient/components/CreditModal/DisputeInvoice';
import DisputeOrder from '../../../../../../View/Sale/OrderClient/SaleOrderClient/components/CreditModal/DisputeOrder';
import DisputeProduct from '../../../../../../View/Sale/OrderClient/SaleOrderClient/components/CreditModal/DisputeProduct';
import { DisputeType, DisputeTypeTranslations } from '../../../../../../typings/orderClientLitige';
import { OrderDocument, OrderType } from '../../../../../../typings/orderDocument';

type Props = {
  onClose: () => void;
  orderState: number;
  products: ByProduct[];
};

export type Payload = {
  reason: string;
  orderDocument?: number;
  products?: {
    byProduct: number;
    quantity: number;
  }[];
  refundCarrier?: boolean;
  refundPayment?: boolean;
};

const DisputeModal: React.FC<Props> = ({ onClose, orderState, products }) => {
  const { uuid } = useParams<{ uuid: string }>();
  const [type, setType] = useState<DisputeType>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [documents, setDocuments] = useState<OrderDocument[]>([]);

  useEffect(() => {
    ApiHandler.get<OrderDocument[]>(
      {
        route: 'api_order_document_list',
        params: { uuid: uuid as string },
        data: { type: OrderType.INVOICE_ORDER_CLIENT },
      },
      (response) => {
        if (response.data) {
          setDocuments(response.data);
        }
        setLoading(false);
      },
    );
  }, []);

  const onSubmit = (data: Payload): void => {
    setLoadingSubmit(true);
    ApiHandler.post(
      {
        route: 'api_order_document_new_credit',
        data: {
          type,
          ...data,
        },
        params: { uuid: uuid as string },
      },
      (response) => {
        const success = response.status === 200;
        const errorMessage = response.error?.message || "Une erreur inattendue s'est produite.";

        dispatch(
          SnackbarOpen({
            text: success ? 'Avoir enregistré avec succès.' : errorMessage,
            variant: success ? 'success' : 'error',
          }),
        );

        if (success) {
          onClose();
        }
        setLoadingSubmit(false);
      },
    );
  };

  const disputeOrderProducts = products.filter(
    (product) => product.amount - product.amountShipped - product.amountLitige > 0,
  );
  const disputeInvoiceDocuments = documents.filter((document) => document.orderClientLitiges.length === 0);
  const disputeProductDocuments = documents.map((document) => {
    if (document.orderClientLitiges.length === 0) {
      return document;
    }

    const itemsDispute = document.orderClientLitiges.reduce((acc: Record<number, number>, orderClientLitige) => {
      orderClientLitige.orderClientLitigeByProducts.forEach((orderClientLitigeByProduct) => {
        acc[orderClientLitigeByProduct.byProduct] = orderClientLitigeByProduct.amount;
      });
      return acc;
    }, {});

    return {
      ...document,
      orderDocumentByProducts: document.orderDocumentByProducts.map((orderDocumentByProduct) => ({
        ...orderDocumentByProduct,
        quantity:
          orderDocumentByProduct.quantity - (itemsDispute[orderDocumentByProduct.orderClientByProduct.byProduct] || 0),
      })),
    };
  });

  const canDisputeProductDocuments = disputeProductDocuments.some((document) =>
    document.orderDocumentByProducts.some((orderDocumentByProduct) => orderDocumentByProduct.quantity > 0),
  );

  const optionsDisputeType = (Object.keys(DisputeTypeTranslations) as DisputeType[])
    .filter((disputeType: DisputeType): boolean => {
      switch (disputeType) {
        // case DisputeType.OUT_OF_STOCK:
        //   return !!disputeOrderProducts.length;
        case DisputeType.RETURN_TO_SENDER:
        case DisputeType.RETRACTION:
        case DisputeType.PACKAGE_DAMAGED:
        case DisputeType.PACKAGE_LOST:
        case DisputeType.SHIPPING_FEES:
          // case DisputeType.PROMO_CODE:
          return !!disputeInvoiceDocuments.length;
        case DisputeType.PRODUCT_RETRACTION:
        case DisputeType.PRODUCT_DAMAGED:
        case DisputeType.NON_COMPLIANT_PRODUCT:
        case DisputeType.MISSING_PRODUCT:
        case DisputeType.WRONG_ITEM:
          return canDisputeProductDocuments;
        case DisputeType.ORDER_CANCELLATION:
          return orderState === 2; // OrderClient state = Confirmée
        default:
          return false;
      }
    })
    .map((disputeType) => ({
      value: disputeType,
      label: DisputeTypeTranslations[disputeType],
    }));

  const getFormByType = (): React.ReactNode => {
    switch (type) {
      case DisputeType.OUT_OF_STOCK:
        return (
          <DisputeOrder onSubmit={onSubmit} products={disputeOrderProducts} onClose={onClose} loading={loadingSubmit} />
        );
      case DisputeType.RETURN_TO_SENDER:
      case DisputeType.RETRACTION:
      case DisputeType.PACKAGE_DAMAGED:
      case DisputeType.PACKAGE_LOST:
      case DisputeType.SHIPPING_FEES:
      case DisputeType.PROMO_CODE:
        return (
          <DisputeInvoice
            onSubmit={onSubmit}
            documents={disputeInvoiceDocuments}
            onClose={onClose}
            loading={loadingSubmit}
          />
        );
      case DisputeType.PRODUCT_RETRACTION:
      case DisputeType.PRODUCT_DAMAGED:
      case DisputeType.NON_COMPLIANT_PRODUCT:
      case DisputeType.MISSING_PRODUCT:
      case DisputeType.WRONG_ITEM:
        return (
          <DisputeProduct
            onSubmit={onSubmit}
            documents={disputeProductDocuments}
            onClose={onClose}
            loading={loadingSubmit}
          />
        );
      case DisputeType.ORDER_CANCELLATION:
      default:
        return <></>;
    }
  };

  return (
    <Dialog open maxWidth="xl" onClose={onClose}>
      <DialogTitle style={{ fontSize: 15 }}>Créer un avoir</DialogTitle>
      <DialogContent style={{ minWidth: '30vw' }}>
        <Grid container spacing={2}>
          {loading ? (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          ) : optionsDisputeType.length ? (
            <Grid item xs={12}>
              <SelectInput
                id="type"
                label="Type *"
                textHelper="Choisissez un type de litige."
                onChange={(event) => setType(event.target.value as DisputeType)}
                options={optionsDisputeType}
              />
            </Grid>
          ) : (
            <Grid item container xs={12} alignItems="center" justifyContent="space-between" spacing={2}>
              <Grid item>Impossible de créer plus d'avoir pour cette commande</Grid>
              <Grid item>
                <ButtonComponent color="#5E6E82" label="Annuler" onClick={onClose} loading={loadingSubmit} />
              </Grid>
            </Grid>
          )}

          {getFormByType()}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DisputeModal;
