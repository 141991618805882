import { PaymentMethod } from '../typings/orderPayment';

export enum OrderType {
  DELIVERY_NOTE_ORDER_CLIENT = 'delivery_note_order_client',
  INVOICE_ORDER_CLIENT = 'invoice_order_client',
  CREDIT_ORDER_CLIENT = 'credit_order_client',
  INVOICE_ORDER_PURVEYOR = 'invoice_order_purveyor',
}

export const OrderTypeTranslation: Record<OrderType, string> = {
  [OrderType.DELIVERY_NOTE_ORDER_CLIENT]: 'Bon de livraison client',
  [OrderType.INVOICE_ORDER_CLIENT]: 'Facture client',
  [OrderType.CREDIT_ORDER_CLIENT]: 'Avoir client',
  [OrderType.INVOICE_ORDER_PURVEYOR]: 'Facture fournisseur',
};

export type TaxTypeId = '2' | '3' | '5' | '10' | '12' | '19';

export const TaxTypeTranslation: Record<TaxTypeId, string> = {
  2: '5.5%',
  3: '20%',
  5: '6%',
  10: '8%',
  12: '21%',
  19: '17%',
};

export type OrderDocument = {
  id: number;
  orderType: OrderType;
  reference: string;
  pricePaidTTC: number;
  orderDocumentByProducts: {
    id: number;
    orderClientByProduct: {
      byProduct: number;
    };
    productLabel: string;
    quantity: number;
  }[];
  orderClientLitiges: {
    orderClientLitigeByProducts: {
      amount: number;
      byProduct: number;
    }[];
  }[];
};

export type OrderDocumentStats = {
  date: string;
  type: OrderType;
  reference: string;
  totalProductsHT: number;
  totalHT: number;
  totalTTC: number;
  totalTVA: number;
  totalShippingFees: number;
  totalPaymentFees: number;
  taxRates: Partial<
    Record<
      TaxTypeId,
      {
        base: number;
        rate: number;
        total: number;
      }
    >
  >;
  paymentMethod: PaymentMethod;
};
