export enum MovementAction {
  INCREASE_STOCK = 1,
  DECREASE_STOCK = 2,
  TRANSFER_STOCK = 3,
  SUPPLIER_PURCHASE = 4,
  BTOC_SALE = 5,
  BTOB_SALE = 6,
  SHOP_RESTOCK = 7,
  CUSTOMER_RETURN = 8,
  CONSUMPTION = 9,
  INVENTORY = 10,
  AMAZON_SALES = 11,
}

export const MovementActionTranslation: Record<MovementAction, string> = {
  [MovementAction.INCREASE_STOCK]: 'Augmenter le stock',
  [MovementAction.DECREASE_STOCK]: 'Diminuer le stock',
  [MovementAction.TRANSFER_STOCK]: 'Transfert de stock vers un autre dépôt',
  [MovementAction.SUPPLIER_PURCHASE]: 'Achat Fournisseur',
  [MovementAction.BTOC_SALE]: 'Vente BtoC',
  [MovementAction.BTOB_SALE]: 'Vente BtoB',
  [MovementAction.SHOP_RESTOCK]: 'Réapprovisionnement boutique',
  [MovementAction.CUSTOMER_RETURN]: 'Retour client',
  [MovementAction.CONSUMPTION]: 'Consommation',
  [MovementAction.INVENTORY]: 'Inventaire',
  [MovementAction.AMAZON_SALES]: 'Vente Amazon',
};

export type MovementStockList = {
  createdAt: number;
  amount: number;
  price: number;
  sellPrice: number;
  balance: number;
  comment: string | null;
};
