import { Article, CloudDownload, PriceCheck } from '@mui/icons-material';
import { Grid, IconButton, Slide, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import { dispatch } from '../../../App';
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from '../../../Component/TableComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TitleComponent from '../../../Component/TitleComponent';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { getRoutePathname } from '../../../Handler/RouteHandler';
import { isNumber } from '../../../Util/NumberTool';
import { DisputeType } from '../../../typings/orderClientLitige';

function BillingRefundList() {
  const [optionsChannel, setOptionsChannel] = React.useState([]);
  const [optionsStatus, setOptionsStatus] = React.useState([]);

  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientEmail',
      label: 'Email',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientName',
      label: 'Client',
      options: { filter: true, sort: true },
    },
    {
      name: 'channel',
      label: 'Canal',
      options: { filter: true, sort: true },
    },
    {
      name: 'orderClientState',
      label: 'Etat Commande',
      options: { filter: true, sort: true },
    },
    {
      name: 'type',
      label: 'Type Litige',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de création',
      options: { filter: true, sort: true },
    },
    {
      name: 'confirmedAt',
      label: 'Date de facturation',
      options: { filter: true, sort: true },
    },
    {
      name: 'refundPriceHT',
      label: 'Prix HT',
      options: { filter: true, sort: true },
    },
    {
      name: 'refundPriceTTC',
      label: 'Prix TTC',
      options: { filter: true, sort: true },
    },
    {
      name: 'refundAskingAt',
      label: 'Demandé le',
      options: { filter: true, sort: true },
    },
    {
      name: 'authorRefundAsking',
      label: 'Demandé par',
      options: { filter: true, sort: true },
    },
    {
      name: 'repaidAt',
      label: 'Remboursé le',
      options: { filter: true, sort: true },
    },
    {
      name: 'authorRepaid',
      label: 'Remboursé par',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];
  const getAction = (row) => {
    return (
      <>
        <Tooltip title={'Passer en Remboursé'} placement="left">
          <IconButton
            disabled={row.repaidAt !== null}
            onClick={() => {
              ApiHandler.get({ route: 'api_billing_credit_repaid', params: { uuid: row.uuid } }, (response) => {
                if (response.status === 200) {
                  dispatch(
                    SnackbarOpen({
                      text: 'Paiement enregistré.',
                      variant: 'success',
                    }),
                  );
                  dispatch(TableReload('api_billing_refund_list'));
                } else {
                  dispatch(
                    SnackbarOpen({
                      text: response?.error?.message || "Une erreur inattendue s'est produite.",
                      variant: 'error',
                    }),
                  );
                }
              });
            }}
          >
            <PriceCheck style={{ color: row.repaidAt ? '' : '#9c27b0' }} />
          </IconButton>
        </Tooltip>
        <Tooltip disabled={row.type === 'Annulation de la commande'} title={"Télécharger l'avoir"} placement="left">
          <IconButton
            onClick={() => {
              fetch(ApiHandler.route({ route: 'api_billing_credit_download', params: { uuid: row.uuid } }), {
                method: 'GET',
                mode: 'no-cors',
                referrerPolicy: 'no-referrer',
              })
                .then((res) => res.blob())
                .then((res) => {
                  const aElement = document.createElement('a');
                  aElement.setAttribute('download', row.uuid + '.pdf');
                  const href = URL.createObjectURL(res);
                  aElement.href = href;
                  aElement.setAttribute('target', '_blank');
                  aElement.click();
                  URL.revokeObjectURL(href);
                });
            }}
          >
            <CloudDownload style={{ color: row.type === DisputeType.ORDER_CANCELLATION ? '' : '#006500' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Visualiser'} placement="left">
          <Link to={getRoutePathname('client_service_order_client_litige', { uuid: row.uuid })}>
            <IconButton>
              <Article style={{ color: '#17a2b8' }} />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  };

  // FormSearch
  const [formSearch, setFormSearch] = React.useState({
    number: {
      name: 'number',
      label: 'Référence',
      textHelper: 'Rechercher par référence.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_billing_refund_list'] && cacheTableServer['api_billing_refund_list'].number
          ? cacheTableServer['api_billing_refund_list'].number
          : '',
    },
    clientEmail: {
      name: 'clientEmail',
      label: 'Email',
      textHelper: 'Rechercher par email.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_billing_refund_list'] && cacheTableServer['api_billing_refund_list'].clientEmail
          ? cacheTableServer['api_billing_refund_list'].clientEmail
          : '',
    },
    clientName: {
      name: 'clientName',
      label: 'Client',
      textHelper: 'Rechercher par client.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_billing_refund_list'] && cacheTableServer['api_billing_refund_list'].clientName
          ? cacheTableServer['api_billing_refund_list'].clientName
          : '',
    },
    channel: {
      name: 'channel',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'integer',
      defaultValue:
        cacheTableServer['api_billing_refund_list'] && cacheTableServer['api_billing_refund_list'].channel
          ? cacheTableServer['api_billing_refund_list'].channel
          : 0,
    },
    status: {
      name: 'status',
      label: 'Statut de remboursement',
      textHelper: 'Rechercher par statut.',
      type: 'text',
      defaultValue:
        cacheTableServer['api_billing_refund_list'] && cacheTableServer['api_billing_refund_list'].status
          ? cacheTableServer['api_billing_refund_list'].status
          : 'TOUS',
    },
  });
  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  const inputSearchChange = (index, value) => {
    handlerFormSearch.setValue(index, value);
    cacheTablePage['api_billing_refund_list'] = 0;
    cacheTableServer['api_billing_refund_list'].page = 0;
    cacheTableServer['api_billing_refund_list'][index] = value;
    dispatch(TableReload('api_billing_refund_list'));
  };

  React.useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get({ route: 'api_select_channel' }, (response) => {
      const data = [{ value: 0, label: 'Tous' }, ...response.data];
      setOptionsChannel(data);
    });

    const defaultStatusRefund = [
      { value: 'TOUS', label: 'Tous' },
      { value: 'NOT_REFUND', label: 'Non remboursé' },
      { value: 'REFUND', label: 'Remboursé' },
    ];
    setOptionsStatus(defaultStatusRefund);
  }, []);

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des commandes client', context: 'Vente' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title={'Filtres'} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextFieldComponent
                  id={'number'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('number', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <TextFieldComponent
                  id={'clientEmail'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('clientEmail', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <TextFieldComponent
                  id={'clientName'}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('clientName', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <SelectComponent
                  id={'channel'}
                  options={optionsChannel}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('channel', val)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                <SelectComponent
                  id={'status'}
                  options={optionsStatus}
                  handler={handlerFormSearch}
                  onChange={(val) => inputSearchChange('status', val)}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>

      <br />

      <TableComponent
        id={'api_billing_refund_list'}
        title={'Commandes client'}
        columns={columns}
        promiseServerData={(resolve, options) => {
          const datas = handlerFormSearch.getData();
          const filters = {
            limit: options.rowsPerPage,
            offset: options.page * options.rowsPerPage,
            sortName: options.sortOrder.name,
            sortDirection: options.sortOrder.direction,
            number: formSearch.number.value ? formSearch.number.value : '',
            clientEmail: formSearch.clientEmail.value ? formSearch.clientEmail.value : '',
            clientName: formSearch.clientName.value ? formSearch.clientName.value : '',
            channel: datas.channel === 0 || !datas.channel ? '' : datas.channel,
            status: formSearch.status.value ? formSearch.status.value : '',
          };

          ApiHandler.get(
            {
              route: 'api_billing_refund_list',
              data: filters,
            },
            (response) => {
              const data = response.data.data;
              for (const index in data) {
                data[index].action = getAction(data[index]);
                data[index].createdAt = moment(data[index].createdAt).format('lll');
                data[index].confirmedAt = moment(data[index].confirmedAt).format('lll');
                data[index].repaidAt = data[index].repaidAt ? moment(data[index].repaidAt).format('lll') : '-';
                data[index].authorRepaid = data[index].authorRepaid ?? '-';
                data[index].clientEmail = data[index].clientEmail ?? '-';
                data[index].clientName = data[index].clientName ?? '-';
                data[index].channel = data[index].channel ?? '-';
                data[index].orderClientState = data[index].orderClientState ?? '-';
                data[index].refundPriceHT = isNumber(data[index].refundPriceHT)
                  ? parseFloat(data[index].refundPriceHT).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
                data[index].refundPriceTTC = isNumber(data[index].refundPriceTTC)
                  ? parseFloat(data[index].refundPriceTTC).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                  : '-';
              }
              resolve(data, response.data.count);
            },
          );
        }}
        search={false}
      />
      <br />
      <br />
    </ContentViewComponent>
  );
}

export default BillingRefundList;
