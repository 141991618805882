import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import DataGrid from '../../../../Component/DataGrid';
import ApiHandler from '../../../../Handler/ApiHandler';
import { transformObjectToDataGridRowsArray } from '../../../../Util/DataGridDataTransformer';
import { momentDateFormatter } from '../../../../Util/MomentDateFormatter';
import { Filters } from '../../../../View/Statistic/Preparation/StatisticPreparation';

type Props = {
  filters: Filters;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

type UserPreparation = {
  userPreparerName?: string | null;
  hours: number;
  commands: number;
  commandsPerHour: number;
  numberOfProducts: number;
  numberErrors: number;
};

const List: React.FC<Props> = ({ filters, loading, setLoading }) => {
  const [userPreparations, setUserPreparations] = useState<UserPreparation[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'userPreparerName',
      headerName: 'Préparateur',
      flex: 1,
    },
    {
      field: 'hours',
      headerName: 'Heures affectées',
      valueFormatter: (value) => momentDateFormatter.hoursToString(value),
      flex: 1,
    },
    {
      field: 'commands',
      headerName: 'Commandes préparées',
      flex: 1,
    },
    {
      field: 'commandsPerHour',
      headerName: 'Commandes  par heure',
      valueFormatter: (value) => Number.parseFloat(value).toFixed(2),
      flex: 1,
    },
    {
      field: 'numberOfProducts',
      headerName: 'Produits préparés',
      flex: 1,
    },
    {
      field: 'numberErrors',
      headerName: 'Erreur de préparation',
      flex: 1,
    },
  ];

  const handleSearch = () => {
    setLoading(true);
    ApiHandler.get<UserPreparation[]>(
      {
        route: 'api_statistic_preparations',
        data: filters,
      },
      (response) => {
        setUserPreparations(response.data ?? []);
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    handleSearch();
  }, [filters]);

  return (
    <DataGrid
      rows={transformObjectToDataGridRowsArray<UserPreparation>(userPreparations)}
      columns={columns}
      loading={loading}
      hideFooter
      disableColumnSorting
    />
  );
};

export default List;
