import { TextField, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import UseAnimations from 'react-useanimations';
import visibility from 'react-useanimations/lib/visibility';

type Props = {
  id: string;
  handler: {
    form: Record<string, any>;
    setValue: (id: string, value: string) => void;
    getErrorByField: (input: any) => string;
    setError: (id: string, error: string) => void;
  };
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onScan?: (value: string) => void;
  style?: React.CSSProperties;
  inputRef?: React.Ref<HTMLInputElement>;
  autoComplete?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  adornment?: React.ReactNode;
  adornmentOrientation?: 'left' | 'right';
  disabled?: boolean;
};

const TextFieldComponent: React.FC<Props> = ({
  id,
  handler,
  onChange,
  onBlur,
  onFocus,
  onScan,
  style,
  inputRef,
  autoComplete,
  autoFocus,
  multiline,
  adornment,
  adornmentOrientation,
  disabled,
}) => {
  const input = handler.form[id];
  const label = input.label;
  const type = input.type;

  const classes = useStyles();
  const [error, setError] = useState<string>(input.error);
  const [isFocus, setFocus] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(type === 'password');
  const isVisibility = type === 'password';

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (input.value !== undefined && input.value !== value) {
      setValue(input.value?.toString());
    }
  }, [input.value]);

  useEffect(() => {
    if (input.error !== error) {
      setError(input.error);
    }
  }, [input.error]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let newValue = event.target.value ?? '';

    if (newValue) {
      if (type === 'integer') {
        if (!/^(-?\d*)$/.test(newValue)) {
          newValue = value;
        }
      } else if (type === 'float') {
        if (!/^(-?\d*(\.\d*)?)$/.test(newValue)) {
          newValue = value;
        }
      }

      if (input.options?.max) {
        const length = type === 'text' || type === 'password' ? newValue.toString().length : newValue;
        if (input.options.max && length > input.options.max) {
          newValue = value;
        }
      }

      if (input.options?.inf) {
        const length = type === 'text' || type === 'password' ? newValue.toString().length : newValue;
        if (input.options.inf && length >= input.options.inf) {
          newValue = value;
        }
      }

      if (input.options?.equal) {
        const length = type === 'text' || type === 'password' ? newValue.toString().length : newValue;
        if (input.options.equal && length !== input.options.equal) {
          newValue = value;
        }
      }
    }

    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }

    if (!isFocus) {
      setFocus(false);
      handler.setValue(id, newValue);
      const error = handler.getErrorByField(input);
      setError(error);
      handler.setError(id, error);
    }
  };

  const handleBlur = (): void => {
    setFocus(false);
    handler.setValue(id, value);
    const error = handler.getErrorByField(input);
    setError(error);
    handler.setError(id, error);

    if (onBlur) {
      setTimeout(onBlur, 250);
    }
  };

  const handleFocus = (): void => {
    setFocus(true);
    if (onFocus) {
      setTimeout(onFocus, 250);
    }
  };

  return (
    <div className={classes.content}>
      <TextField
        style={style || {}}
        inputRef={inputRef || null}
        autoComplete={autoComplete === false ? 'off' : 'on'}
        onKeyDown={(e) => {
          if (onScan && e.key === 'Enter') {
            onScan(value);
            setTimeout(() => {
              handler.setValue(id, '');
              setValue('');
            }, 100);
          }
        }}
        multiline={multiline}
        inputProps={{ autoFocus }}
        id={id}
        label={`${label}${input.options?.validation?.includes('required') ? ' *' : ''}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        variant="outlined"
        type={showPassword ? 'password' : 'text'}
        disabled={input.disabled || disabled}
        className={`
          ${classes.textField}
          ${
            isFocus
              ? classes.textFieldFocus
              : error
                ? classes.textFieldError
                : value && !input.disabled && !disabled
                  ? classes.textFieldValid
                  : ''
          }
        `}
        helperText={error && !isFocus ? error : input.textHelper}
        InputProps={
          adornmentOrientation === 'left'
            ? {
                startAdornment: isVisibility ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      disabled={input.disabled || disabled}
                    >
                      <UseAnimations animation={visibility} reverse={showPassword} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  adornment
                ),
              }
            : {
                endAdornment: isVisibility ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      disabled={input.disabled || disabled}
                    >
                      <UseAnimations animation={visibility} reverse={showPassword} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  adornment
                ),
              }
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    padding: 5,
  },
  textField: {
    width: '100%',
    '& input': {
      padding: '7px 14px',
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(13px, 7px) scale(1)',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -7px) scale(0.75)',
    },
    '& .MuiFormHelperText-root': {
      margin: '2px 14px',
      fontSize: 9,
      minHeight: 9,
      lineHeight: '9px',
    },
    '& fieldset': {
      borderWidth: '1px !important',
    },
  },
  textFieldFocus: {
    '& .MuiFormHelperText-root': {
      color: '#3F51BF',
    },
  },
  textFieldValid: {
    '& fieldset': {
      borderColor: '#006500 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#006500',
    },
    '& label': {
      color: '#006500',
    },
  },
  textFieldError: {
    '& fieldset': {
      borderColor: '#982525 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#982525',
    },
    '& label': {
      color: '#982525',
    },
  },
});

export default TextFieldComponent;
