import { Box } from '@mui/material';
import { GridFooter, GridFooterContainer, GridPanelFooter } from '@mui/x-data-grid';
import React from 'react';

import FooterWithTotalGeneral from './FooterWithTotalGeneral';

type FooterField = {
  label: string;
  value: string | number;
};

type Props = {
  footerFields: FooterField[];
  totalGeneral?: number | null;
};

const FooterWith2RowsAndMultipleFields: React.FC<Props> = ({ footerFields, totalGeneral = null }) => {
  return (
    <Box>
      <GridFooterContainer sx={{ justifyContent: 'flex-end', gap: '1rem', marginRight: '1rem' }}>
        {footerFields.map((field, index) => (
          <GridPanelFooter
            key={index}
            sx={{
              backgroundColor: '#ADD8E6',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box>{field.label}</Box>
            <Box>
              <strong>{field.value}</strong>
            </Box>
          </GridPanelFooter>
        ))}
      </GridFooterContainer>

      {totalGeneral !== null ? (
        <FooterWithTotalGeneral totalGeneralValue={totalGeneral} />
      ) : (
        <GridFooterContainer>
          <GridFooter sx={{ border: 'none' }} />
        </GridFooterContainer>
      )}
    </Box>
  );
};

export default FooterWith2RowsAndMultipleFields;
