import React, { useState } from 'react';

import ContentViewComponent from '../../../Component/ContentViewComponent';
import List from '../../../View/InventoryManagement/Stock/components/List';
import Search from '../../../View/InventoryManagement/Stock/components/Search';

export type Filters = {
  createdAt: string | null;
  byProduct: number | null;
  deposit: string | null;
};

const MovementStock: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    createdAt: null,
    byProduct: null,
    deposit: null,
  });

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Liste des movements par produit', context: 'Gestion des stocks' }}>
      <Search handleSearch={(data) => setFilters(data)} loading={loading} />
      <List filters={filters} loading={loading} setLoading={(loading) => setLoading(loading)} />
    </ContentViewComponent>
  );
};

export default MovementStock;
