import moment from 'moment';
import React, { useState } from 'react';

import ContentViewComponent from '../../../Component/ContentViewComponent';
import List from '../../../View/Statistic/Preparation/components/List';
import Search from '../../../View/Statistic/Preparation/components/Search';

export type Filters = {
  startDate: string;
  endDate: string;
  channel: number | null;
  userPreparers: number[];
};

const StatisticPreparation: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    channel: null,
    userPreparers: [],
  });

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Stats préparateurs', context: 'Statistiques' }}>
      <Search filters={filters} handleSearch={(data) => setFilters(data)} loading={loading} />
      <List filters={filters} loading={loading} setLoading={(loading) => setLoading(loading)} />
    </ContentViewComponent>
  );
};

export default StatisticPreparation;
