import { Box, Grid, Slide, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import React, { useCallback, useEffect, useState } from 'react';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import CustomToolbar from '../../../Component/DataGrid/CustomToolbar';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import { displayErrorMessage } from '../../../Error/Errors';
import { fetchStatsChannels, Option } from '../../../Filter/FetchDropdownData';
import ApiHandler from '../../../Handler/ApiHandler';
import { initFormHandler, Form } from '../../../Handler/FormHandler';
import { listingStyles } from '../../../Style/useStyles';
import { replaceCommaWithSpace } from '../../../Util/StringFormatter';

type VariantRow = {
  variantId: number;
  channelId: number;
  channelName: string;
  productName: string;
  sku: string;
  brand: string;
  packaging: string;
  flavor: string;
  productType: string;
  lifeCycle: string;
  quantityCurrentMonth: number;
  quantityJanuary: number;
  quantityFebruary: number;
  quantityMarch: number;
  quantityApril: number;
  quantityMay: number;
  quantityJune: number;
  quantityJuly: number;
  quantityAugust: number;
  quantitySeptember: number;
  quantityOctober: number;
  quantityNovember: number;
  quantityDecember: number;
};

const SalesLast12Months: React.FC = () => {
  const classes = listingStyles();

  const [loadingContent, setLoadingContent] = useState(false);
  const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState(false);
  const [optionsChannels, setOptionsChannels] = useState<Option[]>([]);
  const [cellsTable, setCellsTable] = useState<VariantRow[]>([]);

  const [formSearch, setFormSearch] = useState<Form>({
    channels: {
      name: 'channels',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'array',
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  useEffect(() => {
    handlerFormSearch.start();
    fetchStatsChannels(setOptionsChannels);
  }, []);

  const onSearch = useCallback(() => {
    setDisabledStatusButtonSearch(true);
    setLoadingContent(true);
    dispatch(LinearProgressMainLayoutActivate());

    const datas = handlerFormSearch.getData();
    const filters = {
      channels: datas.channels[0] === null ? [] : datas.channels,
    };

    ApiHandler.get({ route: 'api_statistic_sales_last_12_months', data: filters }, (response) => {
      if (response.status >= 200 && response.status < 300) {
        const dataSalesLast12Months = response.data;
        setCellsTable(dataSalesLast12Months as VariantRow[]);

        dispatch(LinearProgressMainLayoutDeactivate());
      } else {
        displayErrorMessage(response);
      }

      setDisabledStatusButtonSearch(false);
      setLoadingContent(false);
      dispatch(LinearProgressMainLayoutDeactivate());
    });
  }, [formSearch]);

  const exportFileName = 'stats_sells_last_12_months';

  const months = [
    { label: 'January', fr: 'Janvier' },
    { label: 'February', fr: 'Février' },
    { label: 'March', fr: 'Mars' },
    { label: 'April', fr: 'Avril' },
    { label: 'May', fr: 'Mai' },
    { label: 'June', fr: 'Juin' },
    { label: 'July', fr: 'Juillet' },
    { label: 'August', fr: 'Août' },
    { label: 'September', fr: 'Septembre' },
    { label: 'October', fr: 'Octobre' },
    { label: 'November', fr: 'Novembre' },
    { label: 'December', fr: 'Décembre' },
  ];
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const inverseOrderedMonths = [];
  for (let i = 1; i <= 12; i++) {
    const monthIndex = (currentMonthIndex - i + 12) % 12;

    let year = currentYear;

    if (i > currentMonthIndex) {
      year -= 1;
    }

    const monthYear = {
      month: months[monthIndex],
      year: year,
    };
    inverseOrderedMonths.push(monthYear);
  }

  const columns: ({ hidden?: boolean } & GridColDef)[] = [
    {
      field: 'variantId',
      headerName: 'Variant ID',
    },
    {
      field: 'channelName',
      headerName: 'Canal',
      minWidth: 120,
    },
    {
      field: 'productName',
      headerName: 'Désignation produit',
      hideable: false,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => params.value,
      valueFormatter: (value) => replaceCommaWithSpace(value),
    },
    {
      field: 'sku',
      headerName: 'SKU',
      minWidth: 180,
    },
    {
      field: 'brand',
      headerName: 'Marque',
    },
    {
      field: 'packaging',
      headerName: 'Conditionnement',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => params.value,
      valueFormatter: (value) => replaceCommaWithSpace(value),
    },
    {
      field: 'flavor',
      headerName: 'Parfum',
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => params.value,
      valueFormatter: (value) => replaceCommaWithSpace(value),
    },
    {
      field: 'productType',
      headerName: 'Type de produit',
      minWidth: 130,
    },
    {
      field: 'lifeCycle',
      headerName: 'Cycle de vie',
      minWidth: 110,
    },
    {
      field: 'suppliers',
      headerName: 'Fournisseurs',
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => params.value,
      valueFormatter: (value) => replaceCommaWithSpace(value),
    },
    ...inverseOrderedMonths
      .slice()
      .reverse()
      .map(({ month, year }) => ({
        field: `quantity${month.label}`,
        headerName: `${year} ${month.fr}`,
        renderHeader: () => (
          <Typography variant="body2">
            {year}
            <br />
            {month.fr}
          </Typography>
        ),
      })),
    {
      field: `quantityCurrentMonth`,
      headerName: `${currentYear} ${months[currentMonthIndex].fr}`,
      renderHeader: () => (
        <Typography variant="body2">
          {currentYear}
          <br />
          {months[currentMonthIndex].fr}
        </Typography>
      ),
    },
  ];

  const rows = Object.values(cellsTable).map((row) => ({
    ...row,
    id: `${row.variantId}-${row.channelName}`,
  }));

  const getRowsToExport = (): GridRowId[] => {
    return rows.map((row) => row.id);
  };

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Ventes des 12 denièrs mois', context: 'Statistiques' }}>
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title="Filtres" />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
                <MultipleSelectComponent id="channels" options={optionsChannels} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                <ButtonComponent
                  className={classes.buttonActionFirst}
                  label="Rechercher"
                  onClick={() => onSearch()}
                  disabled={disabledStatusButtonSearch}
                  loading={loadingContent}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>
      <br />

      <Box sx={{ height: '68vh', width: '100%' }}>
        <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={rows}
          disableColumnMenu
          rowHeight={25}
          slots={{
            toolbar: () => <CustomToolbar fileName={exportFileName} getRowsToExport={getRowsToExport} />,
          }}
          initialState={{
            columns: { columnVisibilityModel: { variantId: false, suppliers: false } },
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          ignoreDiacritics
          disableRowSelectionOnClick
        />
      </Box>
    </ContentViewComponent>
  );
};

export default SalesLast12Months;
