import { SelectInputProps } from '@mui/material/Select/SelectInput';
import React, { ComponentProps, useEffect, useState } from 'react';

import SelectInput from '../Component/SelectInput';

type Props = Pick<ComponentProps<typeof SelectInput>, 'id' | 'name' | 'options' | 'disabled' | 'withSelectAll'> & {
  handler: {
    form: Record<string, any>;
    setValue: (id: string, value: string | number) => void;
    getErrorByField: (input: any) => string;
    setError: (id: string, error: string) => void;
  };
  onChange?: (value: string | number) => void;
  onBlur?: (value: string | number) => void;
};

const SelectComponent: React.FC<Props> = ({
  id,
  name,
  handler,
  options,
  onChange,
  onBlur,
  disabled,
  withSelectAll,
}) => {
  const input = handler.form[id];
  const label = input.label;

  const [error, setError] = useState<string>(input.error);
  const [value, setValue] = useState<string | number>('');

  useEffect(() => {
    if (input.value !== undefined && input.value !== value) {
      setValue(input.value.toString());
    }
  }, [input.value]);

  useEffect(() => {
    if (input.error !== error) {
      setError(input.error);
    }
  }, [input.error]);

  const handleChange: SelectInputProps['onChange'] = (event) => {
    const newValue = (event.target.value as string | number) ?? '';
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleBlur = (): void => {
    handler.setValue(id, value);
    const validationError = handler.getErrorByField(input);
    setError(validationError);
    handler.setError(id, validationError);

    if (onBlur) {
      onBlur(value);
    }
  };

  return (
    <SelectInput
      id={id}
      name={name}
      options={options}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={input.disabled || disabled}
      error={!!error}
      value={value}
      label={label + (input.options?.validation?.includes('required') ? ' *' : '')}
      textHelper={error ? error : input.textHelper}
      withSelectAll={withSelectAll}
    />
  );
};

export default SelectComponent;
