import { Article } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';

import TableComponent from '../../../../Component/TableComponent';
import { getRoutePathname } from '../../../../Handler/RouteHandler';
import { DisputeStateTranslations, DisputeTypeTranslations } from '../../../../typings/orderClientLitige';

function SaleClientLitigeList(props) {
  const columns = [
    {
      name: 'number',
      label: 'Référence',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientEmail',
      label: 'Email',
      options: { filter: true, sort: true },
    },
    {
      name: 'clientName',
      label: 'Client',
      options: { filter: true, sort: true },
    },
    {
      name: 'channel',
      label: 'Canal',
      options: { filter: true, sort: true },
    },
    {
      name: 'orderClientState',
      label: 'Etat',
      options: { filter: true, sort: true },
    },
    {
      name: 'type',
      label: 'Type de litige',
      options: { filter: true, sort: true },
    },
    {
      name: 'disputeState',
      label: 'Etat du litige',
      options: { filter: true, sort: true },
    },
    {
      name: 'reason',
      label: 'Raison du litige',
      options: { filter: true, sort: true },
    },
    {
      name: 'author',
      label: 'Auteur du litige',
      options: { filter: true, sort: true },
    },
    {
      name: 'createdAt',
      label: 'Date de la commande',
      options: { filter: true, sort: true },
    },
    {
      name: 'litigeAt',
      label: 'Date de litige',
      options: { filter: true, sort: true },
    },
    {
      name: 'dealAt',
      label: 'Date du cloture',
      options: { filter: true, sort: true },
    },
    {
      name: 'action',
      label: 'Action',
      options: { filter: false, sort: false },
    },
  ];

  const getAction = (row) => (
    <Tooltip title={'Visualiser'} placement="left">
      <Link to={getRoutePathname('client_service_order_client_litige', { uuid: row.orderClientLitigeUuid })}>
        <IconButton>
          <Article style={{ color: '#17a2b8' }} />
        </IconButton>
      </Link>
    </Tooltip>
  );

  return (
    <TableComponent
      id="api_sale_client"
      title="Litiges"
      columns={columns}
      noReload={true}
      promiseData={(resolve) => {
        const data = props.client.litiges.data;
        for (const index in data) {
          data[index].action = getAction(data[index]);
          data[index].createdAt = moment(data[index].createdAt).format('lll');
          data[index].dealAt = data[index].dealAt ? moment(data[index].dealAt).format('lll') : '-';
          data[index].litigeAt = moment(data[index].litigeAt).format('lll');
          data[index].number = data[index].number ?? '-';
          data[index].clientEmail = data[index].clientEmail ?? '-';
          data[index].clientName = data[index].clientName ?? '-';
          data[index].channel = data[index].channel ?? '-';
          data[index].orderClientState = data[index].orderClientState ?? '-';
          data[index].disputeState = data[index].disputeState
            ? DisputeStateTranslations[data[index].disputeState]
            : '-';
          data[index].type = DisputeTypeTranslations[data[index].type];
        }
        resolve(data);
      }}
    />
  );
}

export default SaleClientLitigeList;
