import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AuthenticationLogin } from '../../../../Action/AuthenticationAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { hasRight } from '../../../../Common/UserCommon';
import { User } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { Form, initFormHandler } from '../../../../Handler/FormHandler';

type Props = {
  setUser: (user: User) => void;
};

const LoginForm: React.FC<Props> = ({ setUser }) => {
  const [loading, setloading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [form, setForm] = useState<Form>({
    email: {
      name: 'email',
      label: 'Email',
      textHelper: 'Votre adresse email.',
      type: 'text',
      defaultValue: '',
      options: { validation: ['required', 'email'] },
    },
    password: {
      name: 'password',
      label: 'Mot de passe',
      textHelper: 'Votre mot de passe.',
      type: 'password',
      defaultValue: '',
      options: { validation: ['required'] },
    },
  });
  const handlerForm = initFormHandler(form, setForm);
  React.useEffect(() => {
    handlerForm.start();
  }, []);

  const login = () => {
    if (handlerForm.checkError() > 0) {
      return;
    }

    handlerForm.setFormLoading(true);
    setloading(true);

    ApiHandler.post<User>(
      {
        route: 'api_authentication_login',
        data: handlerForm.getData(),
      },
      (response) => {
        if (response.status === 200 && response.data) {
          if (!hasRight(response.data, 'ROLE_SHOP')) {
            setTimeout(() => dispatch(AuthenticationLogin(response.data)), 1500);
          } else {
            setUser(response.data);
          }
        } else if (response.status === 400) {
          handlerForm.setErrorApi(response.error);
        } else {
          dispatch(
            SnackbarOpen({
              text: response?.error?.message || "Une erreur inattendue s'est produite.",
              variant: 'error',
            }),
          );
        }
        handlerForm.setFormLoading(false);
        setloading(false);
      },
    );
  };

  return (
    <>
      <TextFieldComponent id={'email'} handler={handlerForm} />
      <TextFieldComponent id={'password'} handler={handlerForm} />
      <ButtonComponent label="Se connecter" onClick={login} loading={loading} />
    </>
  );
};

export default LoginForm;
