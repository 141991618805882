import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { User } from '../../../Common/UserCommon';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import LoginForm from '../../../View/Authentication/Login/components/Form';
import LoginFormShop from '../../../View/Authentication/Login/components/FormShop';

const Login: React.FC = () => {
  const classes = useStyles();
  const [user, setUser] = useState<User>();

  return (
    <Grid container className={classes.container} justifyContent="center" alignItems="center">
      <ShadowBoxComponent className={classes.box}>
        <Grid container justifyContent="center" direction="column" spacing={1}>
          <img src="/image/logo.webp" alt="" className={classes.logo} />

          {!user && <LoginForm setUser={(user) => setUser(user)} />}
          {user && <LoginFormShop user={user} />}
        </Grid>
      </ShadowBoxComponent>
    </Grid>
  );
};

const useStyles = makeStyles({
  container: {
    height: '100%',
  },
  box: {
    maxWidth: 550,
    width: '100%',
    padding: 25,
  },
  logo: {
    width: 250,
    margin: 'auto',
    marginBottom: 30,
  },
});

export default Login;
