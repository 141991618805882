import moment from 'moment';
import React, { useState } from 'react';

import ContentViewComponent from '../../../Component/ContentViewComponent';
import List from '../../../View/Statistic/Order/components/List';
import ListTotals from '../../../View/Statistic/Order/components/ListTotals';
import Search from '../../../View/Statistic/Order/components/Search';
import { OrderType } from '../../../typings/orderDocument';
import { PaymentMethod } from '../../../typings/orderPayment';

export type Filters = {
  startDate: string | null;
  endDate: string | null;
  channels: number[];
  types: OrderType[];
  paymentMethods: PaymentMethod[];
  shops: number[];
};

const StatisticOrder: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    channels: [],
    types: [],
    paymentMethods: [],
    shops: [],
  });

  return (
    <ContentViewComponent breadcrumbs={{ title: 'Stats factures', context: 'Statistiques' }}>
      <Search filters={filters} handleSearch={(data) => setFilters(data)} loading={loading} />
      <ListTotals filters={filters} />
      <List filters={filters} loading={loading} setLoading={(loading) => setLoading(loading)} />
    </ContentViewComponent>
  );
};

export default StatisticOrder;
