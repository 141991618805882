import { Select, InputLabel, FormControl, FormHelperText, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ComponentProps, useState } from 'react';

export type SelectOption = {
  value: string | number | null;
  label: string;
  customClass?: string;
};

type Props = Pick<ComponentProps<typeof Select>, 'onChange'> & {
  id: string;
  name?: string;
  options: SelectOption[];
  onBlur?: () => void;
  disabled?: boolean;
  error?: boolean;
  value?: any;
  label?: string;
  textHelper?: string;
  withSelectAll?: boolean;
};

const SelectInput: React.FC<Props> = ({
  id,
  name,
  options,
  onBlur,
  onChange,
  disabled = false,
  error = false,
  value,
  label,
  textHelper,
  withSelectAll,
}) => {
  const classes = useStyles();
  const [isFocus, setFocus] = useState<boolean>(false);
  const handleBlur = (): void => {
    setFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleFocus = (): void => {
    setFocus(true);
  };

  return (
    <div className={classes.content}>
      <FormControl
        variant="outlined"
        className={`
          ${classes.select}
          ${isFocus && !disabled ? classes.selectFocus : ''}
          ${error && !disabled ? classes.selectError : ''}
          ${value && !disabled ? classes.selectValid : ''}
        `}
      >
        <InputLabel htmlFor={id} style={value ? { transform: 'translate(15px, -4px) scale(0.75)' } : {}}>
          {label}
        </InputLabel>
        <Select
          value={value}
          defaultValue={value}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          label={label}
          inputProps={{
            name: label,
            id: name,
          }}
          disabled={disabled}
          MenuProps={{ className: classes.menu }}
        >
          {withSelectAll && <MenuItem>Tous</MenuItem>}
          {options.map((data, key) => (
            <MenuItem key={key} className={data.customClass ?? ''} value={data.value as string}>
              {data.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{textHelper}</FormHelperText>
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    padding: 5,
  },
  select: {
    width: '100%',
    marginTop: 0,
    '& .MuiSelect-select': {
      padding: '7px 14px',
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
    },
    '& .MuiSelect-select:focus': {
      background: '#FFFFFF',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(13px, 7px) scale(1)',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(13px, -7px) scale(0.75) !important',
    },
    '& .MuiFormHelperText-root': {
      margin: '2px 14px',
      fontSize: 9,
      height: 9,
      lineHeight: '9px',
    },
    '& fieldset': {
      borderWidth: '1px !important',
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54) !important',
      backgroundColor: '#FFFFFF',
      padding: '0 2px',
    },
  },
  selectFocus: {
    '& fieldset': {
      borderColor: '#3F51BF !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#3F51BF !important',
    },
    '& label': {
      color: '#3F51BF !important',
    },
  },
  selectValid: {
    '& fieldset': {
      borderColor: '#006500 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#006500 !important',
    },
    '& label': {
      color: '#006500 !important',
    },
  },
  selectError: {
    '& fieldset': {
      borderColor: '#982525 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#982525 !important',
    },
    '& label': {
      color: '#982525 !important',
    },
  },
  menu: {
    '& li': {
      fontSize: 12,
      padding: '4px 10px',
      lineHeight: '16px',
      minHeight: '16px',
    },
  },
});

export default SelectInput;
