import { Box } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { GridSortItem } from '@mui/x-data-grid/models/gridSortModel';
import React from 'react';

import { CustomPagination } from '../../../Component/DataGrid/CustomPagination';
import CustomToolbar from '../../../Component/DataGrid/CustomToolbar';
import FooterWithTotalGeneral from '../../../Component/DataGrid/FooterWithTotalGeneral';
import { styleDataGridDefault } from '../../../Style/styleDataGridDefault';
import { MovementAction, MovementActionTranslation } from '../../../typings/movement';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Référence', minWidth: 100 },
  { field: 'byProductSku', headerName: 'SKU', minWidth: 180 },
  { field: 'productName', headerName: 'Article', minWidth: 200 },
  { field: 'mark', headerName: 'Marque', minWidth: 100 },
  { field: 'packSize', headerName: 'Conditionnement', minWidth: 150 },
  { field: 'flavor', headerName: 'Parfum', minWidth: 100 },
  { field: 'amountText', headerName: 'Quantité', minWidth: 80 },
  { field: 'purveyor', headerName: 'Fournisseur', minWidth: 150 },
  { field: 'deposit', headerName: 'Dépôt', minWidth: 100 },
  { field: 'channel', headerName: 'Canal', minWidth: 100 },
  { field: 'comment', headerName: 'Commentaire', minWidth: 150 },
  { field: 'batch', headerName: 'Lot', minWidth: 100 },
  {
    field: 'movementAction',
    headerName: 'Action',
    minWidth: 100,
    valueGetter: (value: MovementAction) => MovementActionTranslation[value],
  },
  { field: 'createdAt', headerName: 'Date', minWidth: 150 },
];

type Props = {
  filters: {
    limit: number;
    offset: number;
    sortName: string;
    sortDirection: string;
  };
  loading: boolean;
  totalItems: number;
  totalAmount: number;
  rows: any[];
  onPaginationModelChange: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange: (model: GridSortModel, details: GridCallbackDetails) => void;
};

const DataTable: React.FC<Props> = ({ totalItems, totalAmount, filters, ...props }) => (
  <Box sx={{ height: 600, width: '100%' }}>
    <DataGrid
      {...props}
      sx={styleDataGridDefault}
      localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      columns={columns}
      rowCount={totalItems}
      disableColumnMenu
      rowHeight={25}
      slots={{
        toolbar: () => <CustomToolbar fileName="movement_list" />,
        pagination: CustomPagination,
        footer: () => (
          <FooterWithTotalGeneral totalGeneralValue={totalAmount} label="Total quantité" valueType="integer" />
        ),
      }}
      paginationModel={{
        pageSize: filters.limit,
        page: Math.floor(filters.offset / filters.limit),
      }}
      ignoreDiacritics
      disableRowSelectionOnClick
      paginationMode="server"
      sortingMode="server"
      sortModel={filters.sortName ? [{ field: filters.sortName, sort: filters.sortDirection } as GridSortItem] : []}
    />
  </Box>
);

export default DataTable;
