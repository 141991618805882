import { Article } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { StateType } from '#app/Store';
import { ByProduct, OrderClientLitige } from '#app/typings/order';

import ButtonComponent from '../../../../../Component/ButtonComponent';
import { getRoutePathname } from '../../../../../Handler/RouteHandler';
import { styleDataGridDefault } from '../../../../../Style/styleDataGridDefault';
import { transformObjectToDataGridRowsArray } from '../../../../../Util/DataGridDataTransformer';
import CreditModal from '../../../../../View/Sale/OrderClient/SaleOrderClient/components/CreditModal';
import DisputeModal from '../../../../../View/Sale/OrderClient/SaleOrderClient/components/DisputeModal';
import {
  DisputeState,
  DisputeStateTranslations,
  DisputeType,
  DisputeTypeTranslations,
} from '../../../../../typings/orderClientLitige';

type Props = {
  disputes: OrderClientLitige[];
  loading: boolean;
  orderState: number;
  products: ByProduct[];
  canOpenDispute?: boolean;
};

const DisputeList: React.FC<Props> = ({ disputes, loading, orderState, products, canOpenDispute = true }) => {
  const authenticationReducer = useSelector((state: StateType) => state.AuthenticationReducer);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCreditModal, setOpenCreditModal] = useState<boolean>(false);
  const columns: GridColDef[] = [
    {
      field: 'ref',
      headerName: 'Réf Simplifiée',
      minWidth: 180,
    },
    {
      field: 'uuid',
      headerName: 'Réf Uuid',
      minWidth: 280,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 200,
      flex: 1,
      valueGetter: (value: DisputeType) => DisputeTypeTranslations[value],
    },
    {
      field: 'state',
      headerName: 'Statut',
      minWidth: 150,
      valueGetter: (value: DisputeState) => DisputeStateTranslations[value],
    },
    {
      field: 'reason',
      headerName: 'Raison',
      flex: 1,
    },
    {
      field: 'author',
      headerName: 'Auteur',
      minWidth: 170,
      flex: 1,
    },
    {
      field: 'litigeAt',
      headerName: 'Date de création',
      minWidth: 150,
      valueGetter: (value) => moment(value).format('lll'),
    },
    {
      field: 'dealAt',
      headerName: 'Date de cloture',
      minWidth: 150,
      valueGetter: (value) => (value ? moment(value).format('lll') : '-'),
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'actions',
      getActions: ({ row }) => [
        <Tooltip title="Visualiser" placement="left">
          <Link to={getRoutePathname('client_service_order_client_litige', { uuid: row.uuid })}>
            <IconButton>
              <Article style={{ color: '#17a2b8' }} />
            </IconButton>
          </Link>
        </Tooltip>,
      ],
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        sx={styleDataGridDefault}
        loading={loading}
        ignoreDiacritics
        columns={columns}
        rows={transformObjectToDataGridRowsArray(disputes)}
        disableColumnMenu
        slots={{
          toolbar: canOpenDispute
            ? () => (
                <GridToolbarContainer>
                  <ButtonComponent color="#F17105" label="Ouvrir un litige" onClick={() => setOpenModal(true)} />
                  {(authenticationReducer.roles.includes('ROLE_CLIENT_SERVICE_ORDER_CLIENT_LITIGE') ||
                    authenticationReducer.roles.includes('ROLE_CLIENT_SERVICE_ORDER_CLIENT_LITIGE_EDIT')) && (
                    <ButtonComponent color="#F1031D" label="Créer un avoir" onClick={() => setOpenCreditModal(true)} />
                  )}
                </GridToolbarContainer>
              )
            : undefined,
        }}
        rowHeight={30}
        disableRowSelectionOnClick
        hideFooter
      />
      {openModal && canOpenDispute && <DisputeModal onClose={() => setOpenModal(false)} products={products} />}
      {openCreditModal && (
        <CreditModal onClose={() => setOpenCreditModal(false)} products={products} orderState={orderState} />
      )}
    </div>
  );
};

export default DisputeList;
