import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import ButtonComponent from '../../../../Component/ButtonComponent';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { Form, initFormHandler } from '../../../../Handler/FormHandler';
import { Filters } from '../../../../View/Statistic/Preparation/StatisticPreparation';
import { ChannelSelect } from '../../../../typings/channel';

type Props = {
  filters: Filters;
  handleSearch: (data: Filters) => void;
  loading: boolean;
};

type SelectOption = {
  label: string;
  value: number;
};

const Search: React.FC<Props> = ({ filters, handleSearch, loading }) => {
  const classes = useStyles();
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [optionsChannel, setOptionsChannel] = useState<ChannelSelect[]>([]);
  const [optionsUserPreparer, setOptionsUserPreparer] = useState<SelectOption[]>([]);

  const [formSearch, setFormSearch] = useState<Form>({
    startDate: {
      name: 'startDate',
      label: 'Date de début',
      textHelper: 'Rechercher par date de début.',
      type: 'date',
      options: { validation: ['date', 'required'] },
      defaultValue: filters.startDate,
    },
    endDate: {
      name: 'endDate',
      label: 'Date de fin',
      textHelper: 'Rechercher par date de fin.',
      type: 'date',
      options: { validation: ['date', 'required'] },
      defaultValue: filters.endDate,
    },
    channel: {
      name: 'channel',
      label: 'Canal',
      textHelper: 'Rechercher par canaux.',
      type: 'integer',
    },
    userPreparers: {
      name: 'userPreparers',
      label: 'Préparateur',
      textHelper: 'Rechercher par préparateur.',
      type: 'array',
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  useEffect(() => {
    handlerFormSearch.start();

    ApiHandler.get<ChannelSelect[]>({ route: 'api_select_channel' }, (response) =>
      setOptionsChannel(response.data ?? []),
    );
    ApiHandler.get<SelectOption[]>({ route: 'api_select_user_preparer' }, (response) =>
      setOptionsUserPreparer(response.data ?? []),
    );
  }, []);

  const getData = (): Filters => {
    const data = handlerFormSearch.getData();

    return {
      startDate: data.startDate,
      endDate: data.endDate,
      channel: data.channel,
      userPreparers: data.userPreparers,
    };
  };

  const download = () => {
    setLoadingExport(true);
    const aElement = document.createElement('a');
    aElement.href = ApiHandler.route({ route: 'api_statistic_preparations_download', data: getData() }) as string;
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);

    setTimeout(() => setLoadingExport(false), 10000);
  };

  return (
    <ShadowBoxComponent className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={3}>
          <DatePickerComponent id="startDate" handler={handlerFormSearch} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DatePickerComponent id="endDate" handler={handlerFormSearch} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <SelectComponent id="channel" options={optionsChannel} handler={handlerFormSearch} withSelectAll />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <MultipleSelectComponent
            id="userPreparers"
            options={optionsUserPreparer}
            handler={handlerFormSearch}
            withSelectAll
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ButtonComponent
            className={classes.button}
            label="Rechercher"
            onClick={() => handleSearch(getData())}
            disabled={loading}
            loading={loading}
          />
          <ButtonComponent
            className={classes.button}
            label="Telecharger"
            onClick={() => download()}
            disabled={loadingExport}
            loading={loadingExport}
          />
        </Grid>
      </Grid>
    </ShadowBoxComponent>
  );
};

const useStyles = makeStyles({
  button: {
    margin: '6px !important',
  },
  container: {
    marginBottom: 20,
  },
});

export default Search;
