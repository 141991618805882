import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AuthenticationLogin } from '../../../../Action/AuthenticationAction';
import { hasRight } from '../../../../Common/UserCommon';
import { User } from '../../../../Common/UserCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import ApiHandler from '../../../../Handler/ApiHandler';
import { Form, initFormHandler } from '../../../../Handler/FormHandler';
import { ShopSelect } from '../../../../typings/shop';

type Props = {
  user: User;
};

const LoginFormShop: React.FC<Props> = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setloading] = useState<boolean>(false);
  const [optionsShop, setOptionsShop] = useState<ShopSelect[]>([]);

  const [form, setForm] = useState<Form>({
    shop: {
      name: 'shop',
      label: 'Boutique',
      textHelper: 'Choisissez votre boutique.',
      type: 'integer',
      defaultValue: user.shops?.length === 1 ? user.shops[0] : undefined,
      options: { validation: ['required'] },
    },
  });
  const handlerForm = initFormHandler(form, setForm);

  React.useEffect(() => {
    handlerForm.start();
    ApiHandler.get<ShopSelect[]>({ route: 'api_select_shop' }, (response) =>
      setOptionsShop(response.data?.filter((shop) => user.shops?.includes(shop.value)) ?? []),
    );
  }, []);

  const login = () => {
    if (handlerForm.checkError() > 0) {
      return;
    }

    handlerForm.setFormLoading(true);
    setloading(true);

    const data = handlerForm.getData();
    dispatch(
      AuthenticationLogin({
        ...user,
        defaultRoute: 'shop',
        shopSelected: data['shop'],
        shopNameSelected: optionsShop.find((shop) => shop.value === data['shop'])?.label,
      }),
    );
  };

  return (
    <>
      <SelectComponent id="shop" options={optionsShop} handler={handlerForm} />

      <ButtonComponent label="Se connecter  à la boutique" onClick={login} loading={loading} />
      {hasRight(user, 'ROLE_WEB') && (
        <ButtonComponent
          className={classes.erp}
          color="#5E6E82"
          label="Se connecter à l'ERP principal"
          onClick={() => dispatch(AuthenticationLogin(user))}
          loading={loading}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  erp: {
    margin: '20px auto 0!important',
  },
});

export default LoginFormShop;
