import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { initFormHandler } from '#app/Handler/FormHandler';

import { Option } from '../typings/utils';

type Props = {
  id: string;
  handler: ReturnType<typeof initFormHandler>;
  loadOptions: (valueText: string, callback: (options: Option[]) => void) => void;
  onClick?: (value: number | string) => void;
  autoFocus?: boolean;
  disabled?: boolean;
};

const AutoCompleteComponent: React.FC<Props> = ({ id, handler, loadOptions, onClick, autoFocus, disabled }) => {
  const input = handler.form[id];
  const label = input.label;

  const classes = useStyles();
  const [error, setError] = useState(input.error);
  const [isFocus, setFocus] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  const [valueText, setValueText] = useState<string>('');
  const [value, setValue] = useState<number | string>();

  useEffect(() => {
    if (input.value !== undefined && input.value.toString() !== value) {
      setValue(input.value.toString());
    }
  }, [input.value]);

  useEffect(() => {
    if (input.valueText !== undefined && input.valueText !== valueText) {
      setValueText(input.valueText.toString());
      setOptions([]);
    }
  }, [input.valueText]);

  useEffect(() => {
    if (input.error !== error) {
      setError(input.error);
    }
  }, [input.error]);

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValueText = event.target.value ?? '';

    setValue(undefined);
    setValueText(newValueText);

    loadOptions(newValueText, (opt) => setOptions(opt));
  };

  const handleClick = (value: number | string, label: string): void => {
    setValue(value);
    setValueText(label);

    setFocus(false);
    handler.setValue(id, value);
    handler.setValueText(id, label);
    const error = handler.getErrorByField(input);
    setError(error);
    handler.setError(id, error);

    if (onClick) {
      onClick(value);
    }
  };

  return (
    <div className={classes.content}>
      <TextField
        inputProps={{ autoFocus, autoComplete: 'off' }}
        id={id}
        label={`${label}${input.options?.validation?.includes('required') ? ' *' : ''}`}
        value={valueText}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={value && isFocus ? () => setFocus(false) : undefined}
        variant="outlined"
        type="text"
        disabled={input.disabled || disabled}
        className={`${classes.textField} ${
          isFocus
            ? classes.textFieldFocus
            : error
              ? classes.textFieldError
              : value && !input.disabled && !disabled
                ? classes.textFieldValid
                : ''
        }`}
        helperText={error && !isFocus ? error : input.textHelper}
      />

      {!value && isFocus && options.length > 0 && (
        <>
          <div onClick={() => setFocus(false)} className={classes.listBackground}></div>
          <div className={classes.list}>
            {options.map((option) => (
              <div key={option.value} className={classes.item} onClick={() => handleClick(option.value, option.label)}>
                {option.label}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    padding: 5,
    boxSizing: 'border-box',
    position: 'relative',
  },
  textField: {
    width: '100%',
    '& input': {
      padding: '7px 14px',
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(13px, 7px) scale(1)',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -7px) scale(0.75)',
    },
    '& .MuiFormHelperText-root': {
      margin: '2px 14px',
      fontSize: 9,
      minHeight: 9,
      lineHeight: '9px',
    },
    '& fieldset': {
      borderWidth: '1px !important',
    },
  },
  textFieldFocus: {
    '& .MuiFormHelperText-root': {
      color: '#3F51BF',
    },
  },
  textFieldValid: {
    '& fieldset': {
      borderColor: '#006500 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#006500',
    },
    '& label': {
      color: '#006500',
    },
  },
  textFieldError: {
    '& fieldset': {
      borderColor: '#982525 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#982525',
    },
    '& label': {
      color: '#982525',
    },
  },
  list: {
    position: 'absolute',
    top: 38,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: '#FFF',
    width: 'calc(100% - 10px)',
    boxSizing: 'border-box',
    borderRadius: '0 0 5px 5px',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    zIndex: 3,
    maxHeight: 120,
    overflow: 'scroll',
  },
  item: {
    color: '#000',
    zIndex: 3,
    padding: '4px 10px',
    cursor: 'pointer',
    fontSize: 12,
    minHeight: 16,
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  listBackground: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    zIndex: 2,
  },
});

export default AutoCompleteComponent;
