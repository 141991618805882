import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { OrderDocument } from '#app/typings/orderDocument';

import ButtonComponent from '../../../../../../Component/ButtonComponent';
import CheckboxComponent from '../../../../../../Component/CheckboxComponent';
import SelectComponent from '../../../../../../Component/SelectComponent';
import TextFieldComponent from '../../../../../../Component/TextFieldComponent';
import { Form, FormField, initFormHandler } from '../../../../../../Handler/FormHandler';
import { Payload } from '../../../../../../View/Sale/OrderClient/SaleOrderClient/components/CreditModal/index';

type Props = {
  documents: OrderDocument[];
  onClose: () => void;
  loading: boolean;
  onSubmit: (payload: Payload) => void;
};

const DisputeProduct: React.FC<Props> = ({ documents, onClose, loading, onSubmit }) => {
  const [orderDocument, setOrderDocument] = useState<number | undefined>(
    documents.length === 1 ? documents[0].id : undefined,
  );
  const [form, setForm] = useState<Form>({
    reason: {
      name: 'reason',
      label: 'Raison',
      textHelper: 'Saisissez une raison.',
      type: 'text',
      options: { validation: ['required'] },
    },
    refundCarrier: {
      name: 'refundCarrier',
      label: 'Rembourser les frais de livraison',
      type: 'boolean',
      defaultValue: false,
    },
    refundPayment: {
      name: 'refundPayment',
      label: 'Rembourser les frais de payment',
      type: 'boolean',
      defaultValue: false,
    },
    ...(documents.length > 1
      ? {
          orderDocument: {
            name: 'orderDocument',
            label: 'Facture',
            textHelper: 'Selectionnez la facture en litige.',
            type: 'integer',
            options: { validation: ['required'] },
          },
        }
      : {}),
    ...(orderDocument
      ? {
          ...documents
            .find((document) => document.id === orderDocument)
            ?.orderDocumentByProducts.filter(({ quantity }) => quantity > 0)
            .reduce((acc: Record<string, FormField>, { orderClientByProduct, quantity }) => {
              acc[orderClientByProduct.byProduct.toString()] = {
                name: orderClientByProduct.byProduct.toString(),
                label: 'Quantité',
                textHelper: `Saisissez la quantité de produit en litige (maximum : ${quantity})`,
                type: 'integer',
                options: {
                  validation: ['required'],
                  max: quantity,
                },
              };
              return acc;
            }, {}),
        }
      : {}),
  });

  useEffect(() => {
    handlerForm.start();
  }, []);

  const handlerForm = initFormHandler(form, setForm);

  const handleSubmit = (): void => {
    if (handlerForm.checkError() < 1) {
      handlerForm.setFormLoading(true);

      const data = handlerForm.getData();

      onSubmit({
        reason: data.reason,
        orderDocument: orderDocument as number,
        products: Object.entries(data)
          .filter(([key]) => !isNaN(parseInt(key)))
          .map(([key, value]) => ({
            quantity: value as number,
            byProduct: parseInt(key),
          })),
        refundCarrier: data.refundCarrier,
        refundPayment: data.refundPayment,
      });
    }
  };

  return (
    <>
      {!!form.reason && (
        <Grid item xs={12}>
          <TextFieldComponent id="reason" handler={handlerForm} multiline />
        </Grid>
      )}
      {!!form.orderDocument && (
        <Grid item xs={12}>
          <SelectComponent
            id="orderDocument"
            options={documents.map((document) => ({
              value: document.id,
              label: document.reference,
            }))}
            handler={handlerForm}
            onChange={(value) => setOrderDocument(value as number)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {!!form.refundCarrier && <CheckboxComponent id="refundCarrier" handler={handlerForm} />}
        {!!form.refundPayment && <CheckboxComponent id="refundPayment" handler={handlerForm} />}
      </Grid>

      {documents
        .find((document) => document.id === orderDocument)
        ?.orderDocumentByProducts.map(
          (orderDocumentByProduct) =>
            !!form[orderDocumentByProduct.orderClientByProduct.byProduct] && (
              <React.Fragment key={orderDocumentByProduct.orderClientByProduct.byProduct}>
                <Grid item xs={6}>
                  <p>{orderDocumentByProduct.productLabel}</p>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    id={orderDocumentByProduct.orderClientByProduct.byProduct.toString()}
                    handler={handlerForm}
                  />
                </Grid>
              </React.Fragment>
            ),
        )}

      <Grid item container spacing={2} justifyContent="end">
        <Grid item>
          <ButtonComponent color="#5E6E82" label="Annuler" onClick={onClose} loading={loading} />
        </Grid>
        <Grid item>
          <ButtonComponent label="Enregistrer" onClick={handleSubmit} loading={loading} />
        </Grid>
      </Grid>
    </>
  );
};

export default DisputeProduct;
