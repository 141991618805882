import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

type Props = {
  id: string;
  handler: {
    form: Record<string, any>;
    setValue: (id: string, value: Moment | null) => void;
    getErrorByField: (input: any) => string;
    setError: (id: string, error: string) => void;
  };
  onChange?: (value: string | null) => void;
  disabled?: boolean;
};

const DatePickerComponent: React.FC<Props> = ({ id, handler, onChange, disabled }) => {
  const input = handler.form[id];
  const classes = useStyles();

  const [error, setError] = useState<string>(input.error);
  const [isFocus, setFocus] = useState<boolean>(false);
  const [value, setValue] = useState<Moment | null>(null);

  // Synchronisation des valeurs
  useEffect(() => {
    if (
      input.value !== '' &&
      input.value !== undefined &&
      (!value || input.value !== value.format('YYYY-MM-DD')) &&
      input.value !== 'Invalid Date'
    ) {
      setValue(moment(input.value));
    }
  }, [input.value]);

  useEffect(() => {
    if (input.error !== error) {
      setError(input.error);
    }
  }, [input.error]);

  // Gestion des événements
  const handleChange = (date: Moment | null): void => {
    setValue(date);

    if (onChange) {
      onChange(date ? date.format('YYYY-MM-DD') : null);
    }

    if (date && date.isValid()) {
      handler.setValue(id, date);
      const error = handler.getErrorByField(input);
      setError(error);
      handler.setError(id, error);
    } else {
      handler.setValue(id, null);
      const error = handler.getErrorByField(input);
      setError(error);
      handler.setError(id, error);
    }

    setFocus(false);
  };

  const handleBlur = (): void => {
    setFocus(false);
  };

  const handleFocus = (): void => {
    setFocus(true);
  };

  return (
    <div className={classes.content}>
      <DatePicker
        className={`
          ${classes.datePicker}
          ${isFocus ? classes.datePickerFocus : error ? classes.datePickerError : value && value.isValid() && !input.disabled && !disabled ? classes.datePickerValid : ''}
        `}
        slotProps={{
          textField: { helperText: error && !isFocus ? error : input.textHelper },
        }}
        label={input.label + (input.options?.validation?.includes('required') ? ' *' : '')}
        value={value}
        onChange={handleChange}
        onClose={handleBlur}
        onOpen={handleFocus}
        disabled={input.disabled || disabled}
      />
    </div>
  );
};

const useStyles = makeStyles({
  content: {
    padding: 5,
  },
  datePicker: {
    width: '100%',
    marginTop: 0,
    '& input': {
      padding: '7px 14px',
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(13px, 7px) scale(1)',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -7px) scale(0.75)',
    },
    '& .MuiFormHelperText-root': {
      margin: '2px 14px',
      fontSize: 9,
      height: 9,
      lineHeight: '9px',
      color: 'rgba(0, 0, 0, 0.5) !important',
    },
    '& fieldset': {
      borderWidth: '1px !important',
      borderColor: 'rgba(0, 0, 0, 0.3) !important',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& svg': {
      width: 16,
      height: 16,
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.5) !important',
    },
  },
  datePickerFocus: {
    '& fieldset': {
      borderColor: '#3F51BF !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#3F51BF !important',
    },
    '& label': {
      color: '#3F51BF !important',
    },
  },
  datePickerValid: {
    '& fieldset': {
      borderColor: '#006500 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#006500 !important',
    },
    '& label': {
      color: '#006500 !important',
    },
  },
  datePickerError: {
    '& fieldset': {
      borderColor: '#982525 !important',
    },
    '& .MuiFormHelperText-root': {
      color: '#982525 !important',
    },
    '& label': {
      color: '#982525 !important',
    },
  },
});

export default DatePickerComponent;
