import { Box, Checkbox, FormControlLabel, Grid, Slide } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  gridExpandedSortedRowIdsSelector,
  gridNumberComparator,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { GridCsvGetRowsToExportParams } from '@mui/x-data-grid/models/gridExport';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate,
} from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import ButtonComponent from '../../../Component/ButtonComponent';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { CustomPagination } from '../../../Component/DataGrid/CustomPagination';
import CustomToolbar from '../../../Component/DataGrid/CustomToolbar';
import FooterWithTotalGeneral from '../../../Component/DataGrid/FooterWithTotalGeneral';
import SelectComponent from '../../../Component/SelectComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import { fetchPurveyors, fetchPurveyorTypes, Option } from '../../../Filter/FetchDropdownData';
import ApiHandler from '../../../Handler/ApiHandler';
import { Form } from '../../../Handler/FormHandler';
import { initFormHandler } from '../../../Handler/FormHandler';
import { styleDataGridDefault } from '../../../Style/styleDataGridDefault';
import { listingStyles } from '../../../Style/useStyles';
import { momentDateFormatter } from '../../../Util/MomentDateFormatter';
import { formatNumberToEurCurrency, formatNumberWithThousandsSeparator } from '../../../Util/NumberTool';
import { momentDateComparator } from '../../../Util/SortMomentDate';
import { replaceCommaWithSpace } from '../../../Util/StringFormatter';

type PortfolioRow = {
  id: string | number;
  orderId: number;
  purveyor: string;
  totalProduct: number;
  isSubtotalRow?: boolean;
};

const StatisticBuyPortfolioDetails: React.FC = () => {
  const [loadingContent, setLoadingContent] = useState<boolean>(false);
  const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState<boolean>(false);
  const [withSubTotal, setWithSubTotal] = useState<boolean>(true);
  const [optionsPurveyor, setOptionsPurveyor] = useState<Option[]>([]);
  const [optionsPurveyorType, setOptionsPurveyorType] = useState<Option[]>([]);
  const [cellsTable, setCellsTable] = useState<Record<number, PortfolioRow>>({});
  const [generalTotalCA, setGeneralTotalCA] = useState<number>(0);
  const [subTotalsByOrder, setSubTotalsByOrder] = useState<Record<number, number>>({});

  const searchPurveyor = useLocation().search;
  const purveyorIdFromBuyCa = new URLSearchParams(searchPurveyor).get('purveyorId');

  const classes = listingStyles();

  const [formSearch, setFormSearch] = useState<Form>({
    purveyor: {
      name: 'purveyor',
      label: 'Fournisseur',
      textHelper: 'Rechercher par fournisseur.',
      type: 'integer',
      defaultValue: purveyorIdFromBuyCa ?? null,
    },
    purveyorType: {
      name: 'purveyorType',
      label: 'Type de fournisseur',
      textHelper: 'Rechercher par types de fournisseur.',
      type: 'integer',
    },
  });

  const handlerFormSearch = initFormHandler(formSearch, setFormSearch);
  useEffect(() => {
    handlerFormSearch.start();
    fetchPurveyors(setOptionsPurveyor);
    fetchPurveyorTypes(setOptionsPurveyorType);

    if (purveyorIdFromBuyCa !== null) {
      onSearch();
    }
  }, []);

  const onSearch = (): void => {
    setDisabledStatusButtonSearch(true);
    setLoadingContent(true);

    dispatch(LinearProgressMainLayoutActivate());

    const datas = handlerFormSearch.getData();
    const filters = {
      purveyor: datas.purveyor === 0 || !datas.purveyor ? '' : datas.purveyor,
      purveyorType: datas.purveyorType === 0 || !datas.purveyorType ? '' : datas.purveyorType,
    };

    ApiHandler.get(
      {
        route: 'api_statistic_buy_portfolio_details',
        data: filters,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          const { generalTotalCA, subTotalsByOrder, ...dataPortfolioDetails } = response.data;

          setCellsTable(dataPortfolioDetails as PortfolioRow[]);
          setGeneralTotalCA(generalTotalCA);
          setSubTotalsByOrder(subTotalsByOrder);
        }

        setDisabledStatusButtonSearch(false);
        setLoadingContent(false);
        dispatch(LinearProgressMainLayoutDeactivate());
      },
    );
  };

  const columns: ({ hidden?: boolean } & GridColDef)[] = [
    {
      field: 'orderId',
      headerName: 'Cmd id',
      headerAlign: 'center',
      align: 'center',
      hidden: true,
      cellClassName: (params) =>
        clsx('amount', {
          subtotalRow: params.row.isSubtotalRow,
        }),
    },
    {
      field: 'purveyor',
      headerName: 'Fournisseur',
      flex: 2,
      colSpan: (_, row) => {
        if (row.isSubtotalRow) {
          return 8;
        }
      },
      cellClassName: (params) =>
        clsx('amount', {
          subtotalRow: params.row.isSubtotalRow,
          flexEnd: params.row.isSubtotalRow,
        }),
    },
    {
      field: 'purveyorType',
      headerName: 'Type de fournisseur',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'refProduct',
      headerName: 'Ref produit (SKU)',
      flex: 1,
      minWidth: 140,
    },
    {
      field: 'mark',
      headerName: 'Marque',
      flex: 1,
    },
    {
      field: 'productName',
      headerName: 'Produit',
      flex: 3,
      renderCell: (params: GridRenderCellParams) => params.value,
      valueFormatter: (value) => replaceCommaWithSpace(value),
    },
    {
      field: 'qtyOrdered',
      headerName: 'Quantité',
      headerAlign: 'center',
      flex: 1,
      align: 'right',
      sortComparator: gridNumberComparator,
      renderCell: (params) =>
        params.row.isSubtotalRow ? '' : <div>{formatNumberWithThousandsSeparator(params.value)}</div>,
      valueFormatter: (value) => value,
    },
    {
      field: 'validAt',
      headerName: 'Date de validation',
      headerAlign: 'center',
      flex: 1,
      align: 'right',
      sortComparator: momentDateComparator,
      valueGetter: (value, row) => (row.isSubtotalRow ? '' : momentDateFormatter.formatMomentDate(value, 'DD/MM/YYYY')),
    },
    {
      field: 'deliveryAt',
      headerName: 'Date de livraison',
      headerAlign: 'center',
      flex: 1,
      align: 'right',
      sortComparator: momentDateComparator,
      valueGetter: (value, row) => (row.isSubtotalRow ? '' : momentDateFormatter.formatMomentDate(value, 'DD/MM/YYYY')),
    },
    {
      field: 'priceBuy',
      headerName: "Prix d'achat",
      headerAlign: 'center',
      flex: 1,
      align: 'right',
      sortComparator: gridNumberComparator,
      renderCell: (params) => (
        <div>
          {params.row.isSubtotalRow
            ? formatNumberToEurCurrency(subTotalsByOrder[params.row.orderId])
            : formatNumberToEurCurrency(params.value, 3)}
        </div>
      ),
      colSpan: (_, row) => {
        if (row.isSubtotalRow) {
          return 2;
        }
      },
      cellClassName: (params) =>
        clsx('amount', {
          subtotalRow: params.row.isSubtotalRow,
          flexCenter: params.row.isSubtotalRow,
        }),
    },
    {
      field: 'totalProduct',
      headerName: 'Total Produit',
      headerAlign: 'center',
      flex: 1,
      align: 'right',
      sortComparator: gridNumberComparator,
      renderCell: (params) => <div>{formatNumberToEurCurrency(params.value)}</div>,
    },
  ];

  const rowsWithSubtotals = useMemo(() => {
    const results: PortfolioRow[] = [];
    let currentOrderId: number | null = null;

    Object.values(cellsTable).forEach((row, index) => {
      if (withSubTotal && currentOrderId !== row.orderId) {
        if (currentOrderId !== null) {
          results.push({
            orderId: currentOrderId,
            purveyor: `Sous-total commande: ${currentOrderId}`,
            totalProduct: subTotalsByOrder[currentOrderId],
            isSubtotalRow: true,
            id: `subtotal-${currentOrderId}`,
          });
        }
        currentOrderId = row.orderId;
      }
      results.push({ ...row, id: index });
    });

    if (withSubTotal && currentOrderId) {
      results.push({
        orderId: currentOrderId,
        purveyor: `Sous-total commande: ${currentOrderId}`,
        totalProduct: subTotalsByOrder[currentOrderId],
        isSubtotalRow: true,
        id: `subtotal-${currentOrderId}`,
      });
    }
    return results;
  }, [cellsTable, subTotalsByOrder, withSubTotal]);

  const rowsWithoutSubTotalToExport = ({ apiRef }: GridCsvGetRowsToExportParams): GridRowId[] => {
    const rows = gridExpandedSortedRowIdsSelector(apiRef);
    return rows.filter((rowId) => {
      const row = apiRef.current.getRow(rowId);

      return !row.isSubtotalRow;
    });
  };

  return (
    <ContentViewComponent
      breadcrumbs={{ title: 'Stats portefeuille fournisseur en attente de réception', context: 'Statistiques' }}
    >
      <Slide direction="left" in timeout={750}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ShadowBoxComponent>
            <TitleComponent title="Filtres" />
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <SelectComponent id="purveyor" options={optionsPurveyor} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <SelectComponent id="purveyorType" options={optionsPurveyorType} handler={handlerFormSearch} />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <FormControlLabel
                  control={<Checkbox checked={withSubTotal} onChange={() => setWithSubTotal(!withSubTotal)} />}
                  label="Afficher Sous-Total"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={1} xl={1}>
                <ButtonComponent
                  className={classes.buttonActionFirst}
                  label="Rechercher"
                  onClick={() => onSearch()}
                  disabled={disabledStatusButtonSearch}
                  loading={loadingContent}
                />
              </Grid>
            </Grid>
          </ShadowBoxComponent>
        </Grid>
      </Slide>
      <br />

      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          sx={{
            ...styleDataGridDefault,
            '& .subtotalRow': {
              fontWeight: 'bold',
              backgroundColor: '#e0e0e0',
            },
            '& .flexEnd': {
              justifyContent: 'flex-end',
            },
            '& .flexCenter': {
              justifyContent: 'center',
            },
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={rowsWithSubtotals}
          disableColumnMenu
          rowHeight={25}
          slots={{
            toolbar: () => (
              <CustomToolbar fileName="stats_portfolio_details" getRowsToExport={rowsWithoutSubTotalToExport} />
            ),
            pagination: CustomPagination,
            footer: () => <FooterWithTotalGeneral totalGeneralValue={generalTotalCA} />,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                orderId: false,
              },
            },
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          ignoreDiacritics
          disableRowSelectionOnClick
        />
      </Box>
    </ContentViewComponent>
  );
};

export default StatisticBuyPortfolioDetails;
